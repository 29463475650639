.polling-box
  display: block
  &__content
    background: #f2f2f2
    padding: 10px
  &__inner
    // background: #fff
  .inner
    &__title
      background: $colorBlueSecondary
      padding: 15px 20px
      p, a, span
        font-size: 20px
        font-weight: 300
        color: #fff
        line-height: 1.2
    &__radio-list
      padding: 20px
      background: #fff
      label
        display: inline-block
        cursor: pointer
        position: relative
        margin-bottom: 15px
        padding-left: 25px
        font-size: 13px
        line-height: 1.2
        color: #000
        &::before
          content: ""
          display: inline-block
          width: 17px
          height: 17px
          margin-right: 10px
          position: absolute
          left: 0
          top: 0 !important
          background-color: #eee
        &:hover
          color: $colorBlueSecondary
      input[type=radio]
        display: none
      label:before
        border: 1px transparent solid
        border-radius: 8px 
      input[type=radio]:checked + label:before
        content: "\2022"
        color: $colorBlue
        font-size: 33px
        text-align: center
        line-height: 0.37em
        border-color: $colorBlueSecondary
        
      .radio-list__button
        display: block
        margin: 10px 0 0 0
        width: 100%
        text-align: center
        .button__inner
          display: inline-block
          padding: 5px 30px
          font-size: 12px
          font-weight: 700
          color: #fff
          background: $colorBlueSecondary
          cursor: pointer
          &:hover
            background: $colorBlue

    &__results
      display: none
      .answer
        position: relative
        display: block
        width: 100%
        margin: 5px 0
        background: #fff
        &__text
          position: relative
          display: block
          padding: 10px
          padding-right: 60px
          font-size: 13px
          line-height: 1.4
          z-index: 2
          color: #2f3031
        &__percentage
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          z-index: 0
          .counter
            position: absolute
            height: 17px
            right: 10px
            top: 5px
            bottom: 0
            margin: auto
            font-family: "futura-pt"
            font-size: 15px
            font-weight: 700
            line-height: 1
            z-index: 2
          .bg-chart
            position: absolute
            top: 0
            bottom: 0
            left: 0
            width: 0
            background: #C2DBFF
            color: #fff


