.articles-list
  display: block
  position: relative
  margin: 0
  padding: 20px 0
  border-top: 3px #ddd solid
  border-bottom: 3px #ddd solid
  +clearfix
  &__block
    display: block
    width: 100%
    position: relative
    float: left
    margin-bottom: 30px !important
    padding-top: 5px
    font-size: 0
    +clearfix
    + .articles__block
      margin-top: 20px
  
  &__list
    display: block
    position: relative
    margin: -1.5%
    overflow: hidden
    +clearfix
  &__article
    display: inline-block
    vertical-align: top
    position: relative
    width: 97%
    margin: 1.5%
    overflow: hidden
    +clearfix
    &:not(:last-child)
      padding-bottom: 1.5%
      border-bottom: 2px #ddd solid
  .article
    &__image
      width: 31%
      float: left
      display: block
      position: relative
      margin-right: 3%
      margin-bottom: 10px
      padding-bottom: 20%
      background: #000
      overflow: hidden
      &:hover
        img
          opacity: 0.6

      a
        display: block
        position: absolute
        width: 100%
        height: 100%
      img
        +fluid-image
        border: 1px #fff solid
        
    &__content
      display: inline
    &__title
      display: block
      margin-bottom: 10px
      color: #2f3031
      line-height: 1
      a, p, span
        font-size: 22px
        font-weight: 600
        color: inherit
        line-height: 1.2
      a:hover
        text-decoration: underline
    &__teasers
      color: #555
      a, p, span, ul, ol
        font-size: 14px
        font-weight: 300
        color: inherit
        line-height: 1.4
      a
        text-decoration: underline
    &__infos
      display: block
      margin-bottom: 10px
      +clearfix
      &--bottom
        position: absolute
        bottom: -80px
        left: 0
        right: 0
        .article-one__info
          width: 100%
        .article-one__tags
          width: 100%
          text-align: right
    &__info
      display: block
      float: left
      width: 40%
      &:nth-child(2n + 2)
        width: 60%
        text-align: right
      .date p
        font-size: 12px
        font-weight: 300
        color: #9d9d9c
          
.article__category
  display: block
  margin-bottom: 5px
  line-height: 1
  a, p, span
    padding-bottom: 5px
    font-size: 10px
    font-weight: 400
    text-transform: uppercase
    letter-spacing: 1px
    color: $colorBlue
    &:hover
      text-decoration: underline


+media-query(1100px)
  .articles-list
    display: block
    &__block
      width: 97% !important
    &__article
      width: 97%
      .article__title
        display: block
        margin-bottom: 10px
        color: #2f3031
        line-height: 1
        a, p, span
          font-size: 18px
          font-weight: 500
          color: inherit
          line-height: 1.2
        a:hover
          text-decoration: underline
      .article__teasers
        color: #555
        a, p, span, ul, ol
          font-size: 12px
          font-weight: 300
          color: inherit
          line-height: 1.4
        a
          text-decoration: underline
      .article__image
        width: 45%
        float: left
        display: block
        position: relative
        margin-right: 5%
        margin-bottom: 10px
        padding-bottom: 30%
        background: #000
        overflow: hidden
        &:hover
          img
            opacity: 0.6
        a
          display: block
          position: absolute
          width: 100%
          height: 100%
        img
          +fluid-image
            

+media-query(650px)
  .articles-list
    &__categories
      a
        font-size: 20px
    &__article
      .article__title
        a, p, span
          font-size:  14px !important 
          font-weight: 700 !important 
          line-height:  1.1 !important  
      .article__teasers
        display: none
