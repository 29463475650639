/**
*
* Table of contents
*
**/

.toc__title {
  @include type--medium;
  font-family: $fontHighlight;
  padding-bottom: $lineHeight/2;
  margin-bottom: ($lineHeight/2) - 1;
  border-bottom: 1px solid $colorGrayKeyline;

  @include medium {
    padding-bottom: $lineHeight/2;
    margin-bottom: $lineHeight/2;
  }
}

.toc__list {
  padding-top: 0;

  border-bottom: 1px solid $colorGrayKeyline;
  padding-bottom: ($lineHeight/2) - 1;
  margin-bottom: $lineHeight/2;

  a {
    display: block;
  }
}

.toc__sublist {
  padding-top: 0;
}
