// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@-webkit-keyframes polling {
  0%{width: 0;}
  100%{width: 100%;}
}

@-moz-keyframes polling {
  0%{width: 0;}
  100%{width: 100%;}
}

@-ms-keyframes polling {
  0%{width: 0;}
  100%{width: 100%;}
}

@-o-keyframes polling {
  0%{width: 0;}
  100%{width: 100%;}
}

@keyframes polling {
  0%{width: 0;}
  100%{width: 100%;}
}

@mixin polling($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(polling);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}