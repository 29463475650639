.categories-menu
  display: block
  .nav__items
    display: table
    // width: 100%
    margin: 0
    padding: 0
    padding-top: 5px
    li
      display: table-cell
      position: relative
      width: 5%
      height: 100%
      padding: 5px 0px 10px 0px
      margin: 0
      vertical-align: middle
      text-align: center
      &::before
        display: none
      &.active
        background: $colorBlue
        text-align: center
        border-top-left-radius: 5px
        border-top-right-radius: 5px
        &::before
          content: ""
          display: block
          margin: 0
          padding: 0
          height: 1px
          width: 100%
          position: absolute
          top: auto
          bottom: -1px
          left: 0
          background: $colorBlue
    li:first-child:nth-last-child(1),
    li:first-child:nth-last-child(2),
    li:first-child:nth-last-child(2) ~ li,
    li:first-child:nth-last-child(3),
    li:first-child:nth-last-child(3) ~ li,
    li:first-child:nth-last-child(4),
    li:first-child:nth-last-child(4) ~ li,
    li:first-child:nth-last-child(5),
    li:first-child:nth-last-child(5) ~ li,
    li:first-child:nth-last-child(6),
    li:first-child:nth-last-child(6) ~ li
      width: auto
      padding-left: 10px
      padding-right: 10px
  .nav__item
    display: block
    width: 100%
    height: 100%
    padding: 5px 6px
    font-size: 12px
    font-weight: 300
    color: #fff
    text-align: center
    line-height: 1.2
    &:hover
      text-decoration: underline
      color: #fff


+media-query(1100px)
  .categories-menu
    .nav__items
      li
        border-bottom: 1px lighten($colorBlueSecondary, 10%) solid
 