.breadcrumbs
	display: block
	ul
		display: block
		margin-bottom: 30px
		padding: 0 20px
		+clearfix
		li
			display: block
			float: left
			margin: 0
			padding: 0
			&::before
				display: none
			&:not(:last-child)
				&::after
					content: "\f101"
					display: inline-block
					vertical-align: middle
					padding: 0 5px
					font-family: FontAwesome
					font-size: 12px
					color: #aaa

			a, p
				display: inline-block
				vertical-align: middle
				margin: 0
				padding: 5px
				font-size: 12px
				line-height: 1

+media-query(1100px)
	.breadcrumbs
		ul
			padding: 0 10px