.lock-access
  display: block
  width: 100%
  text-align: center
  overflow: hidden
  p, a, ul, ol
    font-size: 16px
    font-weight: 200
  h1, h2, h3, h4, h5
    margin-bottom: 0
    font-weight: 200
    text-transform: none
    line-height: 1.4
    color: $colorBlue
    strong
      font-weight: 600
  h1
    font-family: 'futura-pt'
    font-size: 36px
    font-weight: 900
  .light-section
    position: relative
    display: block
    width: 100%
    padding: 20px 0 40px 0
    background: #fff
    &::after
      content: ""
      display: block
      height: 10px
      position: absolute
      bottom: 0
      left: 2px
      right: 2px
      margin: auto
      background: url(../../images/footer-border.png) center center repeat-x
  .lock-access
    &__icon
      display: block
      width: 100%
      margin-bottom: 25px
      img
        width: 100%
    &__button
      display: inline-block
      padding: 10px 15px
      font-size: 14px
      color: #fff
      background: $colorBlue
      border-radius: 3px
      &:hover
        background-color: lighten($colorBlue, 5%)
    &__title, &__sub-title
      display: block
      margin-bottom: 20px
      font-size: 18px
      font-weight: 300
      text-align: center
      color: $colorBlueThird
      a
        color: inherit
        text-decoration: underline
    &__sub-title
      font-size: 22px
    &__content
      display: block
      margin-bottom: 20px
      text-align: center
      background: #f2f2f2

.la-content
  &__border
    display: block
    width: 100%
    img
      display: block
      width: 100%
  &__wrapper
    padding: 20px
  &__columns
    display: block
    margin-top: 30px
    padding: 20px 0
    width: 100%
    border-top: 4px #fff solid
    +clearfix
  &__column
    display: block
    width: 50%
    float: left
    padding: 0 15px


form
  fieldset
    margin-bottom: 10px
.la-form
  &--code
    font-size: 0
    .input-button
      display: inline-block
      padding: 1px
      border: 1px #b3c4d2 solid
      border-radius: 3px
    label
      display: block
      width: 100%
      margin-bottom: 5px
      font-size: 16px
      font-weight: 400
      text-align: center
      color: $colorBlueThird
    input, button
      display: inline-block
      vertical-align: middle
      margin: 0
      padding: 15px
      font-size: 18px
      border: none
      border-radius: 0px
      &[type="submit"]
        padding: 15px 30px
        background: $colorBlue url(../../images/icons/unlock.png) center center no-repeat
        background-size: 19px 27px
        &:hover
          background-color: lighten($colorBlue, 5%)

  &--email
    margin-top: 10px
    input, button
      display: block
      width: 100%
      padding: 10px
      font-size: 14px
      border: 1px #b3c4d2 solid
      border-radius: 3px
      &[type="submit"]
        display: inline-block
        width: auto
        font-size: 10px
        text-transform: uppercase
        color: #fff
        line-height: 1
        background: $colorBlue
        border: none