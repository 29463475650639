@import "bourbon"
@import "../_vendor/animate/animate"
@import "../_vendor/font-awesome/font-awesome"
@import "../_vendor/_bootstrap-grid"
// +font-face(Core Humanist Sans, "../../fonts/core-humanist-sans/corehumanistsans-regular", "../../fonts/core-humanist-sans/corehumanistsans-regular", "../../fonts/core-humanist-sans/corehumanistsans-regular", "../../fonts/core-humanist-sans/corehumanistsans-regular")

$blueprint-liquid-grid-columns: 24
$blueprint-liquid-grid-width: 3.167%
$blueprint-liquid-grid-margin: 1.042%
$blueprint-liquid-container-width: 80%
$blueprint-liquid-container-min-width: 950px
$blueprint-liquid-grid-push-pull: -($blueprint-liquid-grid-margin + $blueprint-liquid-grid-width)

$fg-column: 96.66px
$fg-gutter: 0px
$fg-max-columns: 12

$baseColor: #3aa935
$baseFont: "museo-sans", sans-serif
$fontBaseDark: #1d1d1b 
$fontBaseLight: #94adc5

$green: #98c226

$background: #f0f0f0
$siteWidth: 1280px

// $coreHumanist: "Core Humanist Sans", sans-serif

/*** MIXINS ***/
@mixin clearfix
  &:after
    clear: both
    content: ""
    display: block
    visibility: hidden

@mixin wrapper($width)
  display: block
  position: relative
  width: 100%
  max-width: $width
  margin: 0 auto
  padding: 0 20px
  +clearfix

@mixin justified
  &:after
    content: ""
    width: 100%
    display: inline-block
    *zoom: 1

@mixin fluid-image
  position: absolute
  min-width: 101%
  min-height: 100%
  left: -50%
  right: -50%
  top: -1%
  bottom: -1%
  margin: auto
  +backface-visibility(hidden)
  +transform(translateZ(0) scale(1))
  +transition(all 150ms ease-out)

  
@import "../_partials/_layout"