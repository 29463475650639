/**
*
* Media - imgs/videos
*
**/

img,
video,
object {
  max-width: 100%;
}

img {

  .content & {
    margin-top: $lineHeight;
    margin-bottom: $lineHeight;
  }
}
