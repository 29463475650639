// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@-webkit-keyframes svgDraw {
  0%{stroke-dashoffset: 1000;}
  100%{stroke-dashoffset: 0;}
}

@-moz-keyframes svgDraw {
  0%{stroke-dashoffset: 1000;}
  100%{stroke-dashoffset: 0;}
}

@-ms-keyframes svgDraw {
  0%{stroke-dashoffset: 1000;}
  100%{stroke-dashoffset: 0;}
}

@-o-keyframes svgDraw {
  0%{stroke-dashoffset: 1000;}
  100%{stroke-dashoffset: 0;}
}

@keyframes svgDraw {
  0%{stroke-dashoffset: 1000;}
  100%{stroke-dashoffset: 0;}
}

@mixin svgDraw($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(svgDraw);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}