.line-table
	display: table
	.lt
		&-group
			display: table-row-group
		&-row
			display: table-row
		&-td
			display: table-cell
			vertical-align: top
			&:nth-child(1)
				padding-right: 10px
				font-size: 12px
				font-weight: 600
				color: #b3b3b3