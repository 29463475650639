@-webkit-keyframes strokeLong{ 
  from{
    stroke-width: 0;
    stroke-dasharray: 135% 105%;
  }

  to{
    stroke-width: 10;
    stroke-dasharray: 15% 105%;
  }
}

@-moz-keyframes strokeLong{ 
  from{
    stroke-width: 0;
    stroke-dasharray: 135% 105%;
  }

  to{
    stroke-width: 10;
    stroke-dasharray: 15% 105%;
  }
}

@-ms-keyframes strokeLong{ 
  from{
    stroke-width: 0;
    stroke-dasharray: 135% 105%;
  }

  to{
    stroke-width: 10;
    stroke-dasharray: 15% 105%;
  }
}


@keyframes strokeLong{ 
  from{
    stroke-width: 0;
    stroke-dasharray: 135% 105%;
  }

  to{
    stroke-width: 10;
    stroke-dasharray: 15% 105%;
  }
}



@mixin strokeLong($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(strokeLong);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
