@-webkit-keyframes dash{ 
  from{
    width: 0;
  }

  to{
    width: 195px;
  }
}


@keyframes dash{ 
  from{
    width: 0;
  }

  to{
    width: 195px;
  }
}



@mixin dash($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(dash);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
