@-webkit-keyframes lightSpeedIn {
	0% { -webkit-transform: translateX(100%) skewX(-30deg); opacity: 0;}
	60% { -webkit-transform: translateX(-20%) skewX(30deg); opacity: 1;}
	80% { -webkit-transform: translateX(0%) skewX(-15deg); opacity: 1;}
	100% { -webkit-transform: translateX(0%) skewX(0deg); opacity: 1;}
}

@-moz-keyframes lightSpeedIn {
	0% { -moz-transform: translateX(100%) skewX(-30deg); opacity: 0;}
	60% { -moz-transform: translateX(-20%) skewX(30deg); opacity: 1;}
	80% { -moz-transform: translateX(0%) skewX(-15deg); opacity: 1;}
	100% { -moz-transform: translateX(0%) skewX(0deg); opacity: 1;}
}

@-ms-keyframes lightSpeedIn {
	0% { -ms-transform: translateX(100%) skewX(-30deg); opacity: 0;}
	60% { -ms-transform: translateX(-20%) skewX(30deg); opacity: 1;}
	80% { -ms-transform: translateX(0%) skewX(-15deg); opacity: 1;}
	100% { -ms-transform: translateX(0%) skewX(0deg); opacity: 1;}
}

@-o-keyframes lightSpeedIn {
	0% { -o-transform: translateX(100%) skewX(-30deg); opacity: 0;}
	60% { -o-transform: translateX(-20%) skewX(30deg); opacity: 1;}
	80% { -o-transform: translateX(0%) skewX(-15deg); opacity: 1;}
	100% { -o-transform: translateX(0%) skewX(0deg); opacity: 1;}
}

@keyframes lightSpeedIn {
	0% { transform: translateX(100%) skewX(-30deg); opacity: 0;}
	60% { transform: translateX(-20%) skewX(30deg); opacity: 1;}
	80% { transform: translateX(0%) skewX(-15deg); opacity: 1;}
	100% { transform: translateX(0%) skewX(0deg); opacity: 1;}
}

@mixin lightSpeedIn($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(lightSpeedIn);
	@include function(ease-out);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
