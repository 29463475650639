.widget
  display: block
  position: relative
  margin-bottom: 30px
  z-index: 9
  &__title
    display: block
    position: relative
    margin-bottom: 15px
    padding-bottom: 5px
    border-bottom: 3px $colorBlue solid
    &::after
      content: ""
      display: block
      height: 10px
      width: 100%
      position: absolute
      bottom: -11px
      left: 0
      background: url(../../images/footer-border.png) center center repeat-x
    a, span.disabled
      font-family: "futura-pt"
      font-size: 19px
      font-weight: 700
      &:hover
        color: $colorBlueSecondary
      &.disabled
        color: $colorBlue
        cursor: default

    .next *
      display: inline-block
      *display: inline
      vertical-align: middle
      margin: 0 5px
      font-family: FontAwesome
      font-size: 18px
      font-weight: 400
  .show-all
    position: relative
    border-top: 1px #eee solid
    border-bottom: 1px #f2f2f2 solid
    z-index: 10
    a
      display: block
      padding: 10px
      background: #fff
      font-size: 12px
      font-weight: 400
      text-align: center
      text-transform: uppercase
      color: $colorBlueSecondary
      &:hover
        background: #fafafa
      .fa
        margin-left: 5px
        font-size: 10px


@import "_article-sidebar/_sidebar-back";
