@-webkit-keyframes strokeShort{ 
  from{
    stroke-width: 0;
    stroke-dasharray: 135% 105%;
  }

  to{
    stroke-width: 10;
    stroke-dasharray: 5% 35%;
  }
}

@-moz-keyframes strokeShort{ 
  from{
    stroke-width: 0;
    stroke-dasharray: 135% 105%;
  }

  to{
    stroke-width: 10;
    stroke-dasharray: 5% 35%;
  }
}

@-ms-keyframes strokeShort{ 
  from{
    stroke-width: 0;
    stroke-dasharray: 135% 105%;
  }

  to{
    stroke-width: 10;
    stroke-dasharray: 5% 35%;
  }
}


@keyframes strokeShort{ 
  from{
    stroke-width: 0;
    stroke-dasharray: 135% 105%;
  }

  to{
    stroke-width: 10;
    stroke-dasharray: 5% 35%;
  }
}



@mixin strokeShort($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(strokeShort);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
