@-webkit-keyframes lightSpeedOut {
  0% { -webkit-transform: translateX(0%) skewX(0deg); opacity: 1;}
  100% { -webkit-transform: translateX(100%) skewX(-30deg); opacity: 0;}
}

@-moz-keyframes lightSpeedOut {
  0% { -moz-transform: translateX(0%) skewX(0deg); opacity: 1;}
  100% { -moz-transform: translateX(100%) skewX(-30deg); opacity: 0;}
}

@-ms-keyframes lightSpeedOut {
  0% { -ms-transform: translateX(0%) skewX(0deg); opacity: 1;}
  100% { -ms-transform: translateX(100%) skewX(-30deg); opacity: 0;}
}

@-o-keyframes lightSpeedOut {
  0% { -o-transform: translateX(0%) skewX(0deg); opacity: 1;}
  100% { -o-transform: translateX(100%) skewX(-30deg); opacity: 0;}
}

@keyframes lightSpeedOut {
  0% { transform: translateX(0%) skewX(0deg); opacity: 1;}
  100% { transform: translateX(100%) skewX(-30deg); opacity: 0;}
}

@mixin lightSpeedOut($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(lightSpeedOut);
  @include function(ease-in);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
