// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@-webkit-keyframes changeURL {
  0% {transform: translateX(0) scale(1);}
  40% {transform: translateX(-100px) scale(1.3);}
  100% {transform: translateX(2000px);}
}

@-moz-keyframes changeURL {
  0% {transform: translateX(0) scale(1.1);}
  40% {transform: translateX(-100px);}
  100% {transform: translateX(2000px);}
}

@-ms-keyframes changeURL {
  0% {transform: translateX(0) scale(1.1);}
  40% {transform: translateX(-100px);}
  100% {transform: translateX(2000px);}
}

@-o-keyframes changeURL {
  0% {transform: translateX(0) scale(1.1);}
  40% {transform: translateX(-100px);}
  100% {transform: translateX(2000px);}
}

@keyframes changeURL {
  0% {transform: translateX(0) scale(1.1);}
  40% {transform: translateX(-100px);}
  100% {transform: translateX(2000px);}
}

@mixin changeURL($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(changeURL);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
