.list-articles
	display: block
	position: relative
	+clearfix
	&__info
		display: block
		margin-bottom: 10px
		font-weight: 200
	&__options
		display: table
		width: 100%
		padding-bottom: 15px
		> div
			display: table-cell
			vertical-align: middle
			&:last-child
				text-align: right
			.button
				padding: 12px 15px 10px 15px
				font-size: 10px
				text-transform: uppercase
				color: $colorBlueSecondary
				&:hover
					color: #fff
					background: $colorBlueSecondary
					border-color: $colorBlueSecondary

		.la-search
			p, input
				display: inline-block
				vertical-align: middle
				line-height: 1
			p
				margin-right: 5px
				font-size: 12px
				font-weight: 500
			input
				padding: 10px 30px 10px 10px
				font-size: 14px
				line-height: 1.4
				background: #fff url(../../images/icons/search-ico.png) center right no-repeat
				background-size: 26px 26px
				border: 1px lighten($colorBlue, 40%) solid
				border-radius: 3px
	&__edit-bar
		display: none
		padding-bottom: 10px
		.edit-bar__col
			display: inline-block
			vertical-align: middle
			margin-right: 10px
			font-size: 10px
			color: $colorBlue
			.checkbox label
				&::before
					top: -4px
				&::after
					top: -2px
			p, a, label
				font-size: inherit
				font-weight: 500
				text-transform: uppercase
				color: inherit
			label
				cursor: pointer
			input 
				display: inline-block
				vertical-align: middle
.la__list
	border-top: 1px #ddd solid
.la-article
	display: table
	width: 100%
	padding: 20px 0
	border-bottom: 1px #ddd solid
	&:hover
		.la-article__options
			opacity: 1
	&__check, &__date, &__image, &__content, &__options
		display: table-cell
		vertical-align: top
	&__date, &__image
		width: 120px
	&__date
		font-size: 11px
		font-weight: 300
		letter-spacing: 1px
		color: #737373
		line-height: 1.4
		&--calendar
			width: 45px
			padding: 5px 15px 0 0
			.event__date
				width: auto
				.event__month, .event__day
					width: 45px
	&__check
		display: none
		padding: 0 15px 0 0
	&__image
		a, img
			display: block
			width: 100%
	&__description
		h3
			margin: 0
			font-size: 24px
			text-transform: none
			color: #2f3031
			line-height: 1.3
			+ p
				margin: 10px 0
				font-size: 14px
				color: #737373
			a
				color: inherit
				&:hover
					text-decoration: underline
	&__image + &__content
		padding-left: 30px
		.la-article__description
			padding-right: 60px
			h3
				margin-bottom: 10px
				font-size: 18px

	&__options
		width: 40px
		opacity: 0
		+transition(opacity 200ms linear)
		.options
			&__option
				position: relative
				display: block
				padding: 5px 10px
				line-height: 1
				&:hover i
					color: $colorBlueSecondary
				
.tooltip
	display: none
	position: absolute
	top: -5px
	right: 115%
	background: #fff
	box-shadow: 0px 0px 15px 0px #bbb
	z-index: 9999
	&::after
		content: ""
		display: block
		position: absolute
		top: 18px
		right: -4px
		width: 10px
		height: 10px
		background: #fff
		box-shadow: 5px 5px 5px -3px #bbb
		+transform(rotate(-45deg))
	p, .tooltip__options
		display: block
		width: 100%
		text-align: center
		white-space: nowrap
	p
		padding: 10px 20px
		font-size: 18px
		font-weight: 300
		color: lighten($colorBlueSecondary, 10%)
	.tooltip__content
		padding: 5px 10px
		background: #fff
	.tooltip__options
		padding: 5px 20px
		background: #eee
		a
			margin: 0 2px
	.share-link
		display: block
		padding: 3px 0
		font-size: 12px
		white-space: nowrap
		color: $colorBlueSecondary
		i
			margin-right: 5px



.la-breadcrumbs
	display: block
	width: 100%
	margin-bottom: 10px
	li
		display: inline-block
		padding: 0
		list-style-type: none
		&::before
			display: none
		&:last-child
			a, span
				&::after
					display: none
		a, span
			display: inline-block
			vertical-align: middle
			position: relative
			font-size: 11px
			&::after
				content: "\00BB"
				display: inline-block
				vertical-align: middle
				margin: 0 5px 0 7px
		
.list-articles.active
	.list-articles__edit-bar
		display: block
	.la-article__check
		display: block