.events-list
	display: block
	width: 100%
	margin-bottom: 5px
.events
	&__event
		margin-bottom: 10px
		padding-bottom: 12px
		border-bottom: 1px #ddd solid
.event
	&__info
		margin-bottom: 0.4em
		font-size: 12px
		font-weight: 400
		color: #b3b3b3
		line-height: 1
		span
			font-weight: 600
			color: $colorBlueSecondary
	&__description
		font-weight: 200
		line-height: 1.4
	&__options
		padding-top: 5px
		line-height: 1
		a
			i
				display: inline-block
				vertical-align: middle
			&:hover
				color: $colorBlueSecondary