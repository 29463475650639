.articles
  display: block
  position: relative
  margin: 0 -1.5%
  padding-top: 15px
  +clearfix
  &__block
    display: block
    position: relative
    float: left
    margin-bottom: 30px !important
    padding-top: 5px
    border-top: 3px #f2f2f2 solid
    font-size: 0
    +clearfix
    &::before
      content: url(../../images/article-heading.svg)
      position: absolute
      width: 70px
      height: 12px
      top: -20px
      left: 5px
    + .articles__block
      margin-top: 20px
    &--full
      width: 97%
      margin: 0 1.5%
    &--half
      width: 47%
      margin: 0 1.5%
      .articles__article
        width: 97%
        margin: 10px 1.5%


  &__categories
    display: block
    margin-bottom: 15px
    a
      font-family: "futura-pt"
      font-size: 24px
      font-weight: 700
      &:hover
        color: $colorBlueSecondary
    .next *
      display: inline-block
      *display: inline
      vertical-align: middle
      margin: 0 10px
      font-family: FontAwesome
      font-size: 18px
      font-weight: 400

  &__list
    display: block
    position: relative
    margin: -1.5%
    overflow: hidden
    +clearfix
  &__article
    display: inline-block
    vertical-align: top
    position: relative
    width: 47%
    margin: 1.5%
    overflow: hidden
    +clearfix
    &.articles__article--promotion
      width: 97%
      .article__image
        width: 51.5%
        margin-right: 3%
        padding-bottom: 35%
      .article__title
        margin-bottom: 20px
        line-height: 1.2
        a, p, span
          font-size: 36px
          font-weight: 700
          line-height: 1
      .article__teasers
        a, p, span, ol, ul
          font-size: 16px
          line-height: 1.6
  .article
    &__image
      width: 45%
      float: left
      display: block
      position: relative
      margin-right: 5%
      margin-bottom: 10px
      padding-bottom: 30%
      background: #000
      overflow: hidden
      &:hover
        img
          opacity: 0.6
      a
        display: block
        position: absolute
        width: 100%
        height: 100%
      img
        +fluid-image
        border: 1px #fff solid
    &__content
      display: inline
    &__title
      display: block
      margin-bottom: 10px
      color: #2f3031
      line-height: 1
      a, p, span
        font-size: 18px
        font-weight: 500
        color: inherit
        line-height: 1.2
      a:hover
        text-decoration: underline
    &__teasers
      color: #555
      a, p, span, ul, ol
        font-size: 12px
        font-weight: 300
        color: inherit
        line-height: 1.4
      a
        text-decoration: underline
        
.article__category
  display: block
  margin-bottom: 5px
  line-height: 1
  a, p, span
    padding-bottom: 5px
    font-size: 10px
    font-weight: 400
    text-transform: uppercase
    letter-spacing: 1px
    color: $colorBlue
    &:hover
      text-decoration: underline


+media-query(1100px)
  .articles
    display: block
    &__block
      width: 97% !important
    &__article
      width: 97%
      &.articles__article--promotion
        .article__image
          width: 45%
          margin-right: 5%
          padding-bottom: 30%
        .article__title
          margin-bottom: 10px
          color: #2f3031
          line-height: 1
          a, p, span
            font-size: 18px
            font-weight: 500
            color: inherit
            line-height: 1.2
        .article__teasers
          a, p, span, ol, ul
            font-size: 12px
            line-height: 1.4

+media-query(650px)
  .articles
    &__categories
      a
        font-size: 20px
    &__article
      .article__title
        a, p, span
          font-size:  14px !important 
          font-weight: 700 !important 
          line-height:  1.1 !important  
      .article__teasers
        display: none
