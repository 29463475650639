.most-popular-box
	display: block
	position: relative
	&__list
		display: block
		margin: -20px 0
	&__item
		display: table
		margin: 5px 0
		padding: 6px 0
		+clearfix
		&:not(:last-child)
			border-bottom: 1px #ddd solid
	.item
		&__count
			display: table-cell
			vertical-align: top
			p
				display: block
				padding-right: 15px
				font-size: 16px
				font-weight: 900
				color: $colorBlue
				line-height: 1.1
		&__text
			display: table-cell
			vertical-align: top
			a, p
				display: block
				font-size: 15px
				color: #000
			a:hover
				text-decoration: underline
			
