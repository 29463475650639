.articles-promotion
	display: block
	padding: 20px
	background: #f2f2f2
	.articles-list
		margin: 0 !important
		padding: 0
		border: 0px
		.articles-list__block
			margin: 0 !important
			padding: 0

	.articles__link
		display: block
		margin-bottom: 15px
		a, p
			font-family: "futura-pt"
			font-size: 20px
			font-weight: 700