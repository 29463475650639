/**
*
* Related items
*
**/

.related-guides {
  margin-top: $lineHeight*3;
  padding-bottom: ($lineHeight*2) - 2;
  border-top: 2px solid $colorGrayKeyline;
  padding-top: ($lineHeight*2) - 2;
}

.related-guides__list {
  .list-links {
    padding-top: 0;
  }

  a {
    display: block;
  }
}

.related-guides__title {
  @include type--xlarge;
  padding-top: 0;

  @include medium {
    padding-top: 0;
  }
}

.related-guides__main-link {
  text-transform: uppercase;

  &::before {
    content: '#';
    display: inline-block;
    padding-right: 2px;
  }
}
