/**
*
* Table
*
**/
body{
  table {
    margin-top: $lineHeight;
    width: 100%;

    thead {
      color: #ffffff;
      th, td {
        text-align: center;
        display: none;
        color: #333;
        font-weight: 600;
        line-height: 1.2
        // @include type--medium;
      }
    }
    
    

    tr {
      border-bottom: 10px #fff solid;
      @include medium {
        border-bottom: 1px solid #ffffff;
      }
    }

    tbody {
      // background: $colorGrayBackground;
      td {
        display: block;
        padding-top: $lineHeight/2;
        padding-bottom: $lineHeight/2;
        font-size: 14px;
        font-weight: 400;
        padding-left: 35%;
        // padding-left: 105px !important
        // This is to re-plicate the table-headers for mobile
        &::before {
          content: attr(data-th);
          display: inline-block;
          color: #000414;
          border-bottom: 1px #fff solid;
          // background: $colorBlue;
          border-right: 2px solid #ffffff;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 33%;
          max-height: 100%;
          font-family: Arial;
          font-size: 12px;
          font-weight: 700;
          padding: 10px;
          @include medium {
            display: none;
          }
        }
      }
    }

    
    tbody, thead{
      th,
      td {
        position: relative;
        min-height: 75px;
        @include medium {
          display: table-cell;
        }
      }

      th {
        @include medium {
          padding: $lineHeight;
          padding-top: 13px;
          padding-bottom: $lineHeight/2 - 1;
        }
      }

      td {
        @include medium {
          padding: $lineHeight;
          padding-bottom: $lineHeight - 1;
        }
      }
    }
  }
}
td:last-child::after {
  content: "";
  display: block;
  background: #ffffff;
  height: 1px;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  @include medium {
    display: none;
  }
}

.table-2 {
  col {
    width: $mediumContainer / 2;
    @include wide {
      width: $wideContainer / 2;
    }
  }

  th,
  td {
    @include medium {
      &:first-child {
        border-right: 2px solid #ffffff;
      }
    }
  }
}

.table-3 {
  col {
    width: $mediumContainer / 3;
    @include wide {
      width: $wideContainer / 3;
    }
  }

  th,
  td {
    @include medium {
      &:nth-child(2) {
        border-left: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
      }
    }
  }
}

.table-4 {
  col {
    width: $mediumContainer / 4;
    @include wide {
      width: $wideContainer / 4;
    }
  }

  th,
  td {
    @include medium {
      &:nth-child(2),
      &:nth-child(3) {
        border-left: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
      }
    }
  }
}
