.unlocked-access
	display: table
	width: 100%
	border: 2px $colorBlue solid
	&__ico, &__text
		display: table-cell
		vertical-align: middle
	&__ico
		padding: 15px 20px
		img
			display: inline-block
			width: 40px
	&__text
		padding: 20px 30px
		padding-left: 60px
		background: #f2f2f2 url(../../images/unlocked-border-right.jpg) center left no-repeat
		background-size: auto 100%
	h3
		margin-bottom: 10px
		font-family: 'futura-pt'
		font-weight: 800
		text-transform: none
		color: $colorBlue
	p
		font-weight: 300