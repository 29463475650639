/**
*
* Typography
*
**/

// Just normalizing text
// Recommend using padding instead of margin
h1, h2, h3, h4, h5, p {
  margin: 0;
}

// Definitions
.small,
small {
  @include type--small;
}

.base,
p,
ul,
ol {
  @include type--base;
}

.medium,
h4 {
  @include type--medium;
}

.large,
h3 {
  @include type--large;
}
.xlarge,
h2 {
  @include type--xlarge;
}

.xxlarge,
h1 {
  @include type--xxlarge;
}

.huge {
  @include type--huge;
}


li > p {
  padding-top: 0;
}
