.register-box
	display: block
	position: relative
	overflow: hidden
	&::before
		content: ""
		width: 95%
		height: 10px
		position: absolute
		bottom: -3px
		left: 0
		right: 0
		margin: auto
		background: #fff
		border: 1px #ddd solid
		border-top: 0px
		border-radius: 3px
	&__content
		position: relative
		
	&__text
		position: relative
		padding: 35px 30px
		text-align: center
		*
			margin: 0
			padding: 0
			font-size: 24px
			font-weight: 300
			color: #fff
			line-height: 1.2
		strong
			font-size: 29px
			font-weight: 700

	&__buttons
		display: block
		width: 100%
		background: #f2f2f2
	&__image
		display: block
		width: 100%
		overflow: hidden
		img
			+fluid-image
			top: 0
			right: 0
			bottom: auto
			left: auto
			min-width: 100%
			min-height: 0
			max-height: none
			max-width: none
