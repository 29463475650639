.main-articles
  display: block
  width: 100%
  position: relative
  font-size: 48px
  color: #fff
  white-space: nowrap
  overflow: hidden
  &:hover
    .main-article__link
      color: #fff
      text-decoration: underline
    .main-article__image img
      opacity: 0.6
.main-article
  display: inline-block
  position: relative
  width: 100%
  padding-bottom: 45%
  overflow: hidden
  white-space: normal
  &__image
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    overflow: hidden
    background: #000
    img
      +fluid-image
  &__link
    +display(flex)
    +align-items(flex-end)
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    padding: 40px 40px 50px 40px
    overflow: hidden
    color: inherit
    background-color: transparent !important
    +background-image( linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent) );



  &__title
    display: inline-block
    width: 70%
    font-size: 46px
    font-size: 4.5vmin
    font-weight: 400
    text-shadow: 0px 0px 5px #000
    color: inherit
    line-height: 1



// +media-query(1100px)
//   .main-article
//     display: block

+media-query(650px)
  .main-article
    padding-bottom: 80%
    &__image img
      max-width: 190%
      max-height: 150%
    &__link
      padding: 15px 15px 55px 15px
    &__title
      width: 100%
      font-size: 5.5vmin
      font-size: 25px
