.calendar-box
  display: block

  &__content
    display: block
    position: relative
    padding: 10px
    background: #f2f2f2
    +clearfix
  &__events
    margin-top: 20px

  // $light-gray:  #dedede
  $black-text:  #212121
  $trans-white: rgba(255, 255, 255, 0.5)
  $time: 0.5s
  $time-quick: 0.25s
  $red: #ff5a45
  $blue: #71bbd2
  $dark-blue: #3883a3
  $dark-green:  #69a776
  $light-green: #97ce7f
  $dark-gray: #414141
  $light-gray: #ebebeb
  $yellow: #ddca9a


  #mini-clndr
    display: table
    position: relative
    font-family: Asap, Helvetica, Arial
    margin: 0 auto
    width: 100%
    min-height: 325px
    background: #f2f2f2
    +user-select(none)
    .preloader
      display: table-cell
      vertical-align: middle
      background: #fff
      text-align: center
      &__image
        display: inline-block
      

 
  #mini-clndr .clndr
    overflow: hidden
    &::before
      content: ""
      width: 95%
      height: 10px
      position: absolute
      bottom: -3px
      left: 0
      right: 0
      margin: auto
      background: #fff
      border: 1px #ddd solid
      border-top: 0px
      border-radius: 3px
      z-index: 5
    .controls
      position: relative
      padding: 10px
      background-color: $colorBlueSecondary
      color: white
      border-bottom: 1px #fff solid
      +clearfix
      .clndr-previous-button, .clndr-next-button
        position: relative
        width: 30px
        height: 30px
        display: inline-block
        text-align: center
        cursor: pointer
        background: $colorBlue
        border-radius: 50%
        line-height: 30px
        z-index: 2
        &:hover
          background: #fff
          color: #111
      .clndr-next-button
        float: right
      .month
        width: 100%
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        height: 26px
        display: inline-block
        text-align: center
        text-transform: uppercase
        font-weight: 100
        letter-spacing: 1px
        z-index: 1
      
    .days-container
      position: relative
      width: 100%
      +clearfix
      .days
        position: relative
        left: 0
        width: 100%
        height: auto
        background-color: #fff
        -webkit-transition: left 0.5s
        -moz-transition: left 0.5s
        -ms-transition: left 0.5s
        -o-transition: left 0.5s
        transition: left 0.5s
        .day, .empty
          width: 14.25%
          display: inline-block
          padding: 8px 0
          font-size: 12px
          text-align: center
          color: $black-text
          border-right: 1px solid #eee
          &.event
            background-color: #eee
            font-weight: 600
            color: $colorBlue
            cursor: pointer
            &:hover
              background-color: darken($light-gray, 20%)
          &.adjacent-month
            color: rgba(0, 0, 0, 0.3)
        .empty
          height: 31px
          vertical-align: bottom
        .headers        
          background-color: $colorBlue
          .day-header
            width: 14.25%
            display: inline-block
            font-size: 12px
            text-align: center
            color: white
      .events
        position: absolute
        top: 0
        left: 100%
        width: 100%
        height: 100%
        background-color: #fff
        -webkit-transition: left 0.5s;
        -moz-transition: left 0.5s;
        -ms-transition: left 0.5s;
        -o-transition: left 0.5s;
        transition: left 0.5s;
        .headers        
          position: relative      
        .event-header
          width: 100%
          background-color: $colorBlue
          text-align: center
          color: white        
        .x-button
          padding: 5px
          position: absolute
          left: 20px
          font-weight: 700
          font-size: 100%
          color: #fff
          line-height: 1
          cursor: pointer
          &:hover         
            color: white

        .events-list        
          overflow-y: scroll
          height: 90%
          .event        
            padding: 5px 10px 10px 10px
            border-bottom: 1px solid #eee
            &:hover           
              background-color: lighten($light-gray, 4%)
            a           
              display: block
              padding: 5px 0
              position: relative
              font-family: Arial
              font-size: 12px
              letter-spacing: 1px
              background-color: transparent
              color: $black-text
              text-transform: uppercase
              text-decoration: none
              strong
                text-transform: none
              &:hover
                background-color: transparent
                color: $dark-green
            p
              font-size: 10px
              font-style: italic

      &.show-events     
        .days      
          left: -100%
        .events  
          left: 0
        

  .block
    padding: 80px 0
    width: 100%

  .inner
    max-width: 960px
    padding: 0 20px
    margin: 0 auto

  .bg-gray
    background-color: $dark-gray
  .bg-red
    background-color: $red
    a
      color: $red
      &:hover
        background-color: lighten($red, 20%)
        color: #212121
  .bg-blue
    background-color: $blue
  .bg-green
    background-color: $light-green
  .bg-yellow
    background-color: $yellow
  

  // structure
  .header
    padding: 30px 0
  
  .clndr-logo
    margin: 0 auto
    width: 113px
    height: 113px
    background-image: url('./clndr-logo.svg')
  .banner-text
    text-align: center
    color: #f4f4f4
    letter-spacing: 1px
    font-weight: normal
    text-shadow: 2px 2px 2px #212121
    &.below
      margin-top: 30px
      a
        background-color: transparent
        color: $light-green
        &:hover
          color: lighten($light-green, 20%)

  .more-space
    margin-top: 3em