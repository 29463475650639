.footer
	width: 100%
	padding-top: 10px
	position: relative
	background-color: #0b2e62
	color: #fff
	&::before
		content: ""
		display: block
		height: 10px
		width: 100%
		position: absolute
		top: 0
		left: 0
		background: url(../../images/footer-border.png) center center repeat-x
	&__wrapper
		+wrapper($siteWidth)
		padding: 20px 40px
	
	&__bar--bottom
		display: block
		width: 100%
		background: #072249
		+clearfix
	.bar--bottom
		&__blocks
			display: table
			*display: block
			width: 100%
		&__block
			display: table-cell
			*display: block
			*float: left
			width: 50%
			vertical-align: middle
			&:nth-child(even)
				text-align: right
				.out-link
					margin: 0
					margin-left: 20px
			*
				display: inline-block
				vertical-align: middle
				font-size: 12px
				font-weight: 300
				color: lighten($colorBlue, 10%)
			.out-link
				margin: 0
				margin-right: 20px

	.bar--base
		&__blocks
			display: block
			position: relative
			padding: 10px 0
			+clearfix
		&__block
			display: block
			float: left
			width: 33.3%
			+clearfix
			p, a, ul, ol
				font-size: 12px
				font-weight: 300
				color: lighten($colorBlue, 25%)
			.footer__logo
				margin-bottom: 15px
				*
					display: inline-block
					max-width: 100%
			.footer__scroll
				display: none
				*
					font-size: 22px
					font-weight: 300
					color: #fff
			.footer__menu
				width: 50%
				float: left
				padding: 0 10px
				border-left: 1px lighten($colorBlue, 10%) solid
				ul
					display: block
					li
						margin: 0
						padding: 0
						font-size: 12px
						font-weight: 300
						text-transform: uppercase
						color: #fff
						&::before
							display: none
						+ li
							margin-top: 6px
						*
							color: inherit
						a, p
							font-size: 11px
							font-weight: 100
						a:hover
							text-decoration: underline
				
				&--secondary
					ul li
						color: lighten($colorBlue, 15%)
						font-weight: 400
						a, p
							font-weight: 400


+media-query(1100px)
	.footer
		.bar--base
			&__blocks
				padding: 0
			&__block
				width: 100%
				display: block
				+clearfix
				&:not(:last-child)
					margin-bottom: 15px
					padding-bottom: 0
					border-bottom: 1px $colorBlue solid
				.footer__logo
					display: block
					vertical-align: middle
					width: 50%
					float: left
				.footer__scroll
					display: block
					vertical-align: middle
					width: 50%
					float: right
					text-align: right
					
+media-query(650px)
	.footer
		.bar--base
			&__block
				display: block
				text-align: center
				&:not(:last-child)
					margin-bottom: 15px
					padding-bottom: 20px
					border-bottom: 1px $colorBlue solid
				.footer__logo
					display: block
					vertical-align: middle
					width: 100%
					margin-bottom: -15px
					float: none
					text-align: center
				.footer__scroll
					display: block
					vertical-align: middle
					width: 100%
					margin-bottom: 15px
					padding-bottom: 20px
					float: none
					border-bottom: 1px $colorBlue solid
					text-align: center
					
		.bar--bottom
			&__blocks
				display: block
			&__block
				display: block
				padding: 5px 0
				width: 100%
				text-align: center !important
				.out-link
					margin: 0 5px !important
				
						