.tags-box
	display: block
	position: relative
	&__list
		display: block
		margin: 0 -3px
		padding: 10px 0
		+clearfix
	&__item
		display: inline-block
		float: left
		margin: 3px
		padding: 3px 5px 2px 5px
		background: #fff
		font-size: 12px
		font-weight: 400
		color: #000
		border: 1px #ccc solid
		border-radius: 3px