.gallery
  display: block
  margin: 20px 0
  clear: both
  .layout-col--3 &, .layout-col--4 &
    .gallery__images
      margin: 0
    .gallery__image
      width: 100%
      margin: 10px 0
      max-width: none
  .layout-col--4 &
    .gallery__image
      max-width: 250px
  .layout-col--6 &
    .gallery__image
      width: 48% 
  &__images
    display: block
    position: relative
    margin: -1%
    +clearfix
  &__image
    display: block
    width: 31.3%
    min-width: 185px
    position: relative
    margin: 1%
    float: left
    overflow: hidden
    &::after
      content: ""
      display: block
      padding-bottom: 85%
    figure
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      margin: auto
      display: block
      width: 100%
      text-align: center
      background: #f5f5f5
      figcaption
        position: absolute
        display: block
        padding: 7px 10px 5px 10px
        left: 0
        right: 0
        bottom: 0
        background: rgba(0, 0, 0, 0.2)
        text-align: left
        +transition(all 150ms linear)
        p 
          font-size: 12px
          font-weight: 600
          text-transform: uppercase
          color: #fff
          text-shadow: 1px 1px 15px #000;
      .litebox
        display: block
        width: 100%
        height: 100%
        text-align: center
        img
          +transition(opacity 150ms linear)

      img
        display: inline-block
        position: relative;
        top: 50%;
        max-width: 100%
        max-height: 100%
        backface-visibility: hidden
        -webkit-backface-visibility: hidden
        +transform(translateY(-50%));
      
      &:hover
        .litebox
          &::after
            content: "\f065"
            font-family: FontAwesome
            font-size: 30px
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            margin: auto
            padding: 6px
            width: 50px
            height: 50px
            color: #fff
            z-index: 999
            line-height: 1
        .litebox
          img
            opacity: 0.7
          + figcaption
            top: 100%
            
