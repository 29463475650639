.filters
	display: block
	margin-bottom: 30px !important
	padding-bottom: 20px
	border-bottom: 1px #ddd solid
	form
		fieldset
			display: inline-block
			vertical-align: middle
			padding-right: 10px
			&.right
				float: right
				padding-right: 0
			p
				padding-right: 10px
				font-size: 14px
				line-height: 1.4
			label
				padding-right: 5px
				font-size: 16px
				line-height: 1.4
			input, select, textarea, button, .ui-selectmenu-button
				padding: 5px 10px
				width: auto
				font-size: 14px
				font-weight: 400
				background: #fff
				border: 1px #ddd solid
				border-radius: 2px
				line-height: 1.8
			select, button, .ui-selectmenu-button
				display: inline-block
				font-size: 10px
				font-weight: 600
				text-transform: uppercase
				color: $colorBlue
			button:hover
				background: #eee
			input
				&.datepicker
					width: 140px
					padding-right: 40px
					background-image: url(../../images/icons/calendar.png)
					background-position: 115px center
					background-repeat: no-repeat
					color: $colorBlue
					font-size: 14px
					font-style: italic
					cursor: pointer
			.button--prev
				.fa
					padding-right: 5px
			.button--next
				.fa
					padding-left: 5px