.dashboard
  display: table
  width: 100%
  margin: 0 !important
  padding: 0 25px
  section
    margin-bottom: 20px !important
  &__nav, &__content
    display: table-cell
    vertical-align: top
  &__nav
    width: 250px
  &__content
    padding-left: 60px
    color: #111
    


.dash
  display: block
  &__title
    h2
      display: block
      margin: 0
      font-size: 24px
      font-weight: 300
      text-align: left
      color: $colorBlueThird
  &__username
    position: relative
    margin-bottom: 10px
    padding-bottom: 13px
    background: url(../../images/footer-border.png) bottom center repeat-x
    &::after
      content: ""
      position: absolute
      bottom: 5px
      left: 0
      right: 0
      margin: auto
      height: 3px
      background: $colorBlue
    h3, h3 a
      display: block
      margin: 0
      font-family: 'futura-pt'
      font-weight: 600
      font-size: 19px
      text-transform: none
  &__menu
    display: block
    margin-bottom: 10px
    border-bottom: 1px $colorBlue solid
    li
      display: block
      padding: 0
      color: $colorBlue
      list-style-type: none
      border-bottom: 1px #e9e9e9 solid
      &::before
        display: none
      &.active
        p, a, span
          color: #c9c9c9
      p, a, span
        font-size: 14px
        font-weight: 600
        letter-spacing: 1px
        color: $colorBlueThird
        &:hover
          color: $colorBlue
      a
        display: block
        padding: 10px 0
      i
        margin-right: 10px
  &__logout
    a
      display: block
      padding: 5px 0
      font-size: 14px
      font-weight: 600
      &:hover
        color: $colorBlueSecondary
      i
        margin-right: 10px



.time-bar
  position: relative
  display: block
  width: 100%
  height: 5px
  margin: 10px 0
  border: 1px #ddd solid
  border-radius: 10px
  &__line
    position: relative
    display: block
    top: -1px
    height: 5px
    border-radius: 20px
    &.green
      background: #3ab45a
    &.green-light
      background: #9cb43a
    &.yellow
      background: #d2b12c
    &.orange
      background: #df7127
    &.red
      background: #df2727


+media-query(1100px)
  .dashboard
    &__content
      padding-left: 20px
      color: #111
    
+media-query(650px)
  .dashboard
    display: block
    padding: 0
    &__nav, &__content
      display: block
      width: 100%
      padding: 0
    &__nav
      margin-bottom: 30px
    