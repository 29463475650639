@-webkit-keyframes flipOutX {
    0% {-webkit-transform: perspective(400px) rotateX(0deg); opacity: 1;}
  100% {-webkit-transform: perspective(400px) rotateX(90deg); opacity: 0;}
}

@-moz-keyframes flipOutX {
    0% {-moz-transform: perspective(400px) rotateX(0deg); opacity: 1;}
  100% {-moz-transform: perspective(400px) rotateX(90deg); opacity: 0;}
}

@-ms-keyframes flipOutX {
    0% {-ms-transform: perspective(400px) rotateX(0deg); opacity: 1;}
  100% {-ms-transform: perspective(400px) rotateX(90deg); opacity: 0;}
}

@-o-keyframes flipOutX {
    0% {-o-transform: perspective(400px) rotateX(0deg); opacity: 1;}
  100% {-o-transform: perspective(400px) rotateX(90deg); opacity: 0;}
}

@keyframes flipOutX {
    0% {transform: perspective(400px) rotateX(0deg); opacity: 1;}
  100% {transform: perspective(400px) rotateX(90deg); opacity: 0;}
}

@mixin flipOutX($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(flipOutX);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
