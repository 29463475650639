/**
*
* Icon Circle
*
**/

.icon-circle,
.icon-circle--large {
  height: 0;
  width: 0;
  background: $colorGray;
  display: block;
  position: relative;
  border-radius: 100%;
  font-size: 0;
  padding: 22px; // Breaks baseline grid
  margin: 4px auto; // Adds margin top/bottom to fix baseline grid ;)

  i,
  span {
    position: absolute;
    line-height: 0px;
    top: 50%;
    width: 100%;
    left: 0;
    text-align: center;
    color: #ffffff;
    font-size: $fontLarge;
  }

  span {
    font-family: $fontHighlight;
    font-size: $fontLarge;
    font-weight: 700;

    @include medium {
      font-size: $fontXLarge;
    }
  }
}

.icon-circle--large {
  margin-top: 0;
  margin-bottom: 0;
  padding: $lineHeight;
  position: relative;

  i {
    font-size: $fontLarge;

    @include medium {
      font-size: $fontXLarge;
    }
  }

  @include medium {
    padding: ($lineHeight + $lineHeight/2) - 2;
    border: 2px solid #ffffff;

    a & {
      padding: ($lineHeight + $lineHeight/2) - 1;

      box-shadow: inset 0px 0px 0px 1px rgba(#ffffff, .42);
      border: 1px solid;

      // demo transition
      transition: all 100ms linear;
      transform: translateZ(0); // kick in hardware acceleration
    }

    .no-touch a:hover & {
      box-shadow: inset 0px 0px 0px 1px #ffffff;
      transform: scale(1.1);
    }
  }

}

.icon-circle--nav {
  height: 0;
  width: 0;
  background: $colorGray;
  display: block;
  position: relative;
  border-radius: 100%;
  font-size: 0;
  padding: $lineHeight/2;
  margin: 0 auto;

  @include medium {
    padding: 22px; // Breaks baseline grid
    margin-top: 4px; // Adds margin top/bottom to fix baseline grid ;)
    margin-bottom: 4px; // Adds margin top/bottom to fix baseline grid ;)
  }

  i {
    position: absolute;
    line-height: 1px;
    top: 50%;
    width: 100%;
    left: 0;
    text-align: center;
    color: #ffffff;
    font-size: $fontBase;

    @include medium {
      font-size: $fontLarge;
    }
  }
}
