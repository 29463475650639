.article-one
	display: table
	*display: block
	position: relative
	margin-bottom: 80px !important
	padding-bottom: 0
	border-bottom: 5px #ddd solid
	+clearfix
	&__sidebar, &__content
		display: table-cell
		*display: block
		*float: left
		vertical-align: top
		overflow: hidden
	
	&__sidebar
		width: 130px
		padding-right: 40px
		.sidebar__widget
		  display: block
		  margin: 0
		  padding: 10px 0
		  border-top: 1px #ddd solid 
		  text-align: center
		  *
		  	display: inline-block
		  	vertical-align: middle
		  	font-size: 13px
		  	font-weight: 600
		  	color: $colorBlueSecondary
		  	line-height: 1.4
		  button
		  	background: transparent


	.article__category
		margin-bottom: 15px

	&__title
		display: block
		margin-bottom: 10px
		border-bottom: 2px #ddd solid
		color: #333
		h1
			margin: 0
			margin-bottom: 20px
			padding: 0
			font-size: 44px
			font-weight: 600
			line-height: 1.0
		h3
			margin: 0
			margin-bottom: 20px
			text-transform: none
		p
			margin: 0
			margin-bottom: 20px
			font-size: 14px

	&__title + &__text
			padding-top: 40px
	&__text
		padding-bottom: 50px
	&__infos
		display: block
		margin-bottom: 40px
		+clearfix
		&--bottom
			position: absolute
			bottom: -80px
			left: 0
			right: 0
			.article-one__info
				width: 100%
			.article-one__tags
				width: 100%
				text-align: right
	&__info
		display: block
		float: left
		width: 40%
		&:nth-child(2n + 2)
			width: 60%
			text-align: right
		.date p
			font-size: 14px
			font-weight: 300
			color: #9d9d9c
	&__tags
		display: block
		margin: -5px -3px
		font-size: 12px
		a, i
			display: inline-block
			margin: 5px 2px
			color: #555
		a:hover
			color: $colorBlue
			text-decoration: underline
		i
			color: $colorBlue
	&__description
		display: block
		margin-bottom: 30px
	.text
		&--question
			position: relative
			display: block
			width: 100%
			margin-bottom: 30px
			padding-bottom: 20px
			&::after
				content: ""
				display: block
				position: absolute
				bottom: 0
				left: 0 
				margin: auto
				width: 30%
				height: 1px
				background: #ddd
			p, a, ul, ol
				font-size: 16px
				font-weight: 600
		
+media-query(1100px)
	.article-one
		display: block
		&__sidebar, &__content
			display: block
		&__sidebar
			width: 100%
			margin-bottom: 20px
			padding: 0
			border-bottom: 1px #ccc solid
			+clearfix
			img
				display: none !important
				
			.sidebar__widget
				display: block
				padding: 0
				width: 50%
				float: left
				&:hover
					background: $colorBlue
					*
						color: #fff
				a
					display: block
					padding: 20px 

		&__title
			h1
				font-size: 30px