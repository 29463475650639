/**
*
* Articles list
*
**/

.articles-list {
  padding-left: 0;
}

.articles-list__item {

  padding-bottom: $lineHeight * 2;

  &:last-child {
    padding-bottom: $lineHeight * 2 + 1;
  }

  padding-left: 0;

  &::before {
    content: "";
    display: block;
    width: 40%;
    height: 1px;
    box-shadow: inset 0 1px 0 0 $colorGrayKeyline;
    margin-right: 0;
    margin-left: 30%;
  }

  h3 {
    a:hover {
      text-decoration: none;
    }
  }

  p {
    margin-top: $lineHeight;
    margin-bottom: $lineHeight;
  }

  &:first-child {
    padding-top: 0;

    @include medium {
      padding-top: $lineHeight - 2;
    }

    &::before {
      display: none;
    }
  }

}

