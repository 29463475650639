.ui-selectmenu-menu 
  padding: 0
  margin: 0
  position: absolute
  top: 0
  left: 0
  display: none
.ui-selectmenu-menu .ui-menu 
  overflow: auto
  /* Support: IE7 */
  overflow-x: hidden
  padding: 5px 0
  background: #fff
  border: 1px lighten($colorBlue, 10%) solid
  li
    padding: 5px 10px
    list-style-type: none
    font-size: 12px
    cursor: pointer
    &::before
      display: none
    &:hover
      color: #fff
      background: $colorBlue


.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup 
  font-size: 1em
  font-weight: bold
  line-height: 1.5
  padding: 2px 0.4em
  margin: 0.5em 0 0 0
  height: auto
  border: 0
.ui-selectmenu-open 
  display: block


.ui-selectmenu-button
  position: relative
  padding: 8px
  font-size: 14px
  font-weight: 500
  color: $colorBlue
  border: 1px #ddd solid
  border-radius: 2px
  cursor: pointer
  .ui-selectmenu-text
    padding-right: 30px
  .ui-icon
    font-size: 0px
    position: absolute
    right: 8px
    &::after
      content: "\25BC"
      font-family: FontAwesome
      font-size: 10px









// .ui-selectmenu-button 
//   display: inline-block
//   overflow: hidden
//   position: relative
//   text-decoration: none
//   cursor: pointer

// .ui-selectmenu-button span.ui-icon 
//   right: 0.5em
//   left: auto
//   margin-top: -8px
//   position: absolute
//   top: 50%

// .ui-selectmenu-button span.ui-selectmenu-text 
//   text-align: left
//   // padding: 0.4em 2.1em 0.4em 1em
//   display: block
//   line-height: 1.4
//   overflow: hidden
//   text-overflow: ellipsis
//   white-space: nowrap
