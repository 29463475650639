.access-modal
	display: block
	position: fixed
	top: 0
	bottom: 0
	left: 0
	right: 0
	margin: auto
	background: rgba(255, 255, 255, 0.93)
	z-index: 999999
	&__wrapper
		position: relative
		display: table
		width: 100%
		height: 100%
		max-width: 600px
		margin: 0 auto
		padding: 30px
	&__content
		display: table-cell
		vertical-align: middle

	header
		display: table
		width: 100%
		margin-bottom: 20px
		.am__title, .am__buttons
			display: table-cell
			vertical-align: middle
		.am__title
			text-align: left
			h1
				margin: 0
				font-weight: 300
				text-align: left
				color: $colorBlueThird
		.am__buttons
			text-align: right
			button
				color: $colorBlue
				font-size: 12px
				font-weight: 400
				text-transform: uppercase
				background: transparent
				i
					margin-left: 7px
	form
		label
			font-weight: 500
			text-align: left
		.input-button
			display: table
			width: 100%
			.ib__cell
				display: table-cell
				vertical-align: middle
				&.cell--submit
					width: 60px
				input, button
					display: block
					width: 100%
