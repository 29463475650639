/**
*
* Guides List
*
**/


.guides-list {
  overflow: hidden;

  @include medium {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    padding-top: $lineHeight*2;
  }
}

.guides-list__item {
  padding: 0;
  background: #ffffff;
  margin-top: $lineHeight;
  margin-bottom: 0;

  @include medium {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  h3 {
    margin: 0 32px;
  }

  p {
    margin: $lineHeight 32px 0;
  }

  .primary-content {
    @include medium {
      flex: 1;
    }
  }

  .secondary-content {
    position: relative;
    // background: #f5f5f5;
    margin-top: ($lineHeight*2) - 1;
    border-top: 1px solid $colorGrayKeyline;

    @include medium {
      width: 100%; // needed due to parent being flex
    }

    .icon-circle {
      position: absolute;
      top: -$lineHeight - 2;
      left: 50%;
      margin-left: -21px;
      border: 2px solid #ffffff;

      & i {
        font-size: 23px;
      }
    }
  }

  ol {
    margin: $lineHeight 0 0;
    padding: $lineHeight*2 0 $lineHeight*2;
    margin-top: 0;
  }

  &::before {
    display: none;
  }
}
