/**
*
* Next Lessons
*
**/

.next-lessons {
  background: $colorGrayDark;
  padding: $lineHeight $lineHeight $lineHeight * 2;
  margin-top: $lineHeight;
  color: #ffffff;
  position: relative;

  h3 {
    i {
      @include medium {
        display: none;
      }
    }
  }

  &::before,
  &::after {
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    display: none;

    @include medium {
      display: inline-block;
    }
  }

  &::before {
    @include medium {
      content: attr(data-current-lesson);

      font-family: $fontHighlight;
      font-size: $fontBase;
      font-weight: 400;
      line-height: 1;

      background: $colorGrayDark;
      display: inline-block;
      padding: 5px 7px;

      right: 127px;
      top: 143px;

      z-index: 1;
      color: rgba(255, 255, 255, 0.5);
    }

    @include wide {
      font-size: $fontMedium;
      padding-left: 15px;
      padding-right: 15px;
      top: 126px;
      right: 230px;
    }

  }

  &::after {
    @include medium {
      content: $icon-lessons;
      font-family: $fontIcon;
      font-size: 150px;

      right: 40px;
      top: 185px;
    }

    @include wide {
      font-size: 210px;
      right: 120px;
    }

  }
}
