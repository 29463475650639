.form-tabs
	display: block
	position: relative
	nav ul
		display: table
		table-layout: fixed
		width: 100%
		border: 1px #ddd solid
		border-radius: 5px
		overflow: hidden
		li
			display: table-cell
			vertical-align: middle
			padding: 0
			overflow: hidden
			&::before
				display: none
			button
				display: inline-block
				vertical-align: middle
				width: 100%
				min-height: 100%
				padding: 8px 10px
				font-size: 12px
				text-transform: uppercase
				color: $colorBlue
				background: #fff
				border-top: 3px transparent solid
				&.active
					color: #fff
					background: $colorBlue
					border-color: darken($colorBlue, 7%)
	&__tab
		display: none
		&.active
			display: block

