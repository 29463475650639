.pagination
	display: block
	width: 100%
	position: relative
	+clearfix
	&__wrapper
		display: block
		margin: 0 -10px
	&__inner
		display: table
		width: 100%
	&__text, &__numeral
		display: table-cell
		vertical-align: middle
	&__numeral
		text-align: center
		p, a
			margin: 0 10px
			padding: 4px 5px
	&__text
		p *
			color: #999 !important
		a:hover *
			color: #000
	&__text--left
		text-align: left
	&__text--right
		text-align: right
		
	
	p, a, ol, ul, span
		display: inline-block
		vertical-align: middle
		margin: 0 5px
		color: $colorBlue
	p
		color: #999
	.fa
		margin: 0 5px
	ul
		display: block
		width: 100%
		li
			display: inline-block
			vertical-align: middle
			margin: 0
			padding: 0
			&::before
				display: none
			&.active, &:hover
				background: #ddd
				border-radius: 50%
				*
					color: #000

			

	
+media-query(1100px)
	.pagination
		&__text
			p, a, ol, ul, span
				margin: 0
			span
				display: none
			.fa
				font-size: 26px
				font-weight: 700
		&__numeral
			ul li
				&:nth-child(n + 4)
					display: none
					
				