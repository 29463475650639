.user-access
  position: relative
  display: block
  width: 100%
  max-width: 840px
  margin: 0 auto
  padding-top: 50px
  &__name
    display: block
    text-align: center
    h1
      font-weight: 300
      color: $colorBlueThird
  &__cols
    .p-panel
      .types__type
        width: 98%
  &__form
    padding: 20px
    background: #f2f2f2


.user-access--edit
  margin: 30px 0
  padding-top: 0
  form
    padding-top: 0px
    input[type="submit"]
      display: block
      width: auto
      margin: 10px auto 0 auto
  hr
    margin: 20px 0


.dashboard
  h4
    margin-bottom: 0px
    font-size: 18px
    font-weight: 300
    color: #104490
    line-height: 1.6
    span
      display: inline-block
      margin: 10px 0
      padding: 7px 15px 5px 15px
      background: #f2f2f2
      border-radius: 3px