.download
	display: table
	width: 100%
	padding: 20px 0 5px 0
	h1, h2, h3, h4, h5
		margin-bottom: 15px
	.layout-col--3 &, .layout-col--4 &
		display: block
		.file
			&__format
				display: none
			&__description
				padding: 10px
				border: 1px $baseColor solid
	.file
		&__format
			display: table-cell
			vertical-align: top
			padding: 18px 15px 10px 4px
			font-size: 10px !important
			font-weight: 900
			text-transform: uppercase
			color: #0b2e62
			background: url(../../images/icons/download.png) top left no-repeat
		&__description
			display: table-cell
			vertical-align: top
			padding-left: 10px
			border-left: 1px #ccc solid
		&__title
			margin-bottom: 3px
			font-size: 14px
			line-height: 1.1
		&__link
			display: block
			padding-top: 5px
			font-size: 14px
			font-weight: 600
			line-height: 1
			&::before
				content: "\f019"
				display: inline-block
				vertical-align: baseline
				margin-right: 10px
				font-family: FontAwesome
		&__size
			padding-left: 10px
			font-size: 12px
			color: #bbb