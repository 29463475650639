
html body
	.buttons
		display: block
		position: relative
		margin: 0 -5px
		line-height: 1
		&--left
			text-align: left
		&--right
			text-align: right
		&--center
			text-align: center
		a
			font-size: 10px
			font-weight: 400
			text-transform: uppercase
			i
				margin-left: 3px
	.button
		display: inline-block
		vertical-align: middle
		margin: 5px
		font-family: "museo-sans"
		font-size: 14px
		font-weight: 300
		line-height: 1
		border: 1px #ddd solid
		border-radius: 5px
		text-transform: normal
		+transition(all 150ms linear)
		&:hover
			color: #fff
			background: lighten($colorBlueSecondary, 10%)
		&-1
			padding: 12px 15px 10px 15px
			border: 1px #ddd solid
			border-radius: 5px
			background: #fff
			color: $colorBlueSecondary
			text-transform: uppercase
		&--blue, &--white
			font-size: 10px
			font-weight: 200
		&--blue
			background: $colorBlue
			border-color: $colorBlue
			color: #fff !important
		&--white
			background: #fff


		&--xsmall
			padding: 4px 7px 2px 7px
			font-size: 10px
			text-transform: uppercase
		&--small
			padding: 10px 15px 8px 15px
			text-transform: uppercase
		&--large
			margin: 0 10px 0 0
			padding: 13px 50px 11px 50px
			font-size: 14px