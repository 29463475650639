body, html
  display: block
  height: 100%
  color: #444
body
  display: block
  position: relative
  background: $background
  *
    margin: 0
    padding: 0
    max-width: none
    max-height: none
    border: 0px
    box-sizing: border-box
    outline: none
    font-family: $baseFont
    text-decoration: none
    color: inherit
    user-select: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    khtml-user-select: none
  p, ul, ol
    line-height: 1.4em
    font-weight: 400
    color: inherit
  p
    min-height: 15px
  hr
    display: block
    width: 100%
    height: 1px
    margin: 30px 0
    background: #ddd
  strong
    font-weight: 600
  h1, h2, h3, h4 , h5
    margin-bottom: 1em
  h1
    font-size: 38px
    font-weight: 400
  h2
    font-size: 32px
    font-weight: 700
  h3
    font-size: 20px
    font-weight: 900
    text-transform: uppercase
  h4
    font-size: 16px
    font-weight: 400

  ul, li, a 
    margin: 0
    padding: 0
    border: 0
    list-style: none
    line-height: 1
    text-decoration: none
    position: relative
  a 
    line-height: 1.3
    color: $colorBlue
    &:hover
      color: $colorBlue
  img
    display: block
  ul li
    padding-left: 20px
    &::before
      content: "\2022"
      margin-right: 10px
      position: absolute
      top: 0
      bottom: 0
      font-family: FontAwesome
      display: inline-block
      vertical-align: middle
      font-size: 2em
      font-weight: 700
      color: inherit
      line-height: 0.4
    li::before
      display: none
      visibility: hidden      
        
  ol li
    padding-left: 20px



.checkbox
  position: relative
  line-height: 18px
  label
    display: block
    line-height: 1.6em !important

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked 
  position: absolute
  left: -9999px

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label 
  position: relative
  padding-left: 25px
  cursor: pointer


[type="checkbox"]:not(:checked) + label::before ,
[type="checkbox"]:checked + label::before 
  content: ''
  position: absolute
  left: 0
  top: 0px
  width: 17px
  height: 17px
  border: 1px solid #aaa
  background: #f8f8f8
  border-radius: 3px


[type="checkbox"]:not(:checked) + label::after,
[type="checkbox"]:checked + label::after 
  content: '✔'
  position: absolute
  top: 2px
  left: 2px
  font-size: 14px
  color: #333
  transition: all .2s

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label::after 
  opacity: 0
  transform: scale(0)

[type="checkbox"]:checked + label::after 
  opacity: 1
  transform: scale(1)
  line-height: 1

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label::before ,
[type="checkbox"]:disabled:checked + label::before 
  box-shadow: none
  border-color: #bbb
  background-color: #ddd

[type="checkbox"]:disabled:checked + label::after 
  color: #999

[type="checkbox"]:disabled + label 
  color: #aaa

/* accessibility */
[type="checkbox"]:checked:focus + label::before ,
[type="checkbox"]:not(:checked):focus + label::before 
  border: 1px dotted blue


/* hover style just for information */
label:hover:before 
  border: 1px solid #4778d9!important







/* Useless styles, just for demo design */

body 
  font-family: "Open sans", "Segoe UI", "Segoe WP", Helvetica, Arial, sans-serif
  color: #777

h1, h2 
  margin-bottom: 5px
  font-weight: normal
  // text-align: center

h2 
  margin: 5px 0 2em
  color: #aaa

form
  margin: 0 auto

form
  padding-top: 20px
  +clearfix
  fieldset
    margin-bottom: 10px
  p
    display: block
    width: 100%
    font-size: 12px
    font-weight: 600
    color: $colorBlueSecondary
  .fieldset
    &--small
      width: 50%
      float: left
      &:nth-of-type(odd)
        padding-right: 10px
      &:nth-of-type(even)
        padding-left: 10px
  label, input, textarea, select
    display: block
    width: 100%
  label
    margin-bottom: 5px
    font-size: 12px
    line-height: 1
  input, textarea, select
    font-size: 14px
    padding: 8px 15px
    border: 1px #ddd solid
    border-radius: 5px
  [type="submit"]
    margin-top: 10px
    padding: 12px 20px 10px 20px
    font-size: 16px
    text-transform: uppercase
  .checkbox
    padding-top: 10px

  a.right
    display: block
    padding: 10px 0 0px 0
    font-size: 12px
    text-align: right
    text-decoration: underline
    &:hover
      color: $colorBlueSecondary



.form-message
  display: block
  width: 100%
  margin: 20px auto 15px auto
  padding: 15px
  font-size: 14px
  text-align: center
  line-height: 1.4
  border-radius: 3px
  p, a, span
    font-size: inherit
    color: inherit
    line-height: inherit
  &--error
    color: #b23636
    background: #f7ebeb
  &--success
    color: #3E7727
    background: #CCECCD









.txtcenter 
  margin-top: 4em
  font-size: .9em
  text-align: center
  color: #aaa

.copy 
 margin-top: 2em 

.copy a 
 text-decoration: none
 color: #4778d9










table
  margin-bottom: 30px
  thead
    background: #eee
    th, td
      padding: 1% !important
      font-weight: 500
      font-size: 14px
      border: 1px #eee solid
      border-bottom: 2px #ddd solid
      color: #333
  tbody
    td
      padding: 2%
      padding-left: 2%
      border: 1px #eee solid
      font-weight: 400
      line-height: 1.2
      &::before
        background: #eee
        color: #000414

#site
  position: relative
  min-height: 100%
  // overflow: hidden
  &__content
    +wrapper($siteWidth)
    padding: 0
    background: #fff
    overflow: hidden
    .site__wrapper
      display: block
      margin: 0 -1.5%
      padding: 30px 40px
      overflow: hidden
      +clearfix
    .site__title
      display: block
      margin-bottom: 20px !important
      h2, p, span, a
        margin: 0
        padding: 0
        font-size: 36px
        font-weight: 300
        color: $colorBlueSecondary
    .site__base
      width: 68%
      margin: 0 1.5%
      float: left
      overflow: hidden
      &--dashboard
        width: 100%
        float: none
      section
        margin-bottom: 40px
    .site__aside
      width: 26%
      margin: 0 1.5%
      float: left
      

    // RESPONSIVE SECTIONS //
    .site__small-screen
      display: none
      margin: 0 1.5%
      section
        margin-bottom: 30px
    .site__middle-screen
      display: none
      margin: 0 1.5%
      section
        margin-bottom: 30px

  
.two-cols
  +columns(2)
  orphans: 3


.middle-screen--hide
  display: block
  +media-query(1100px)
    display: none !important
    
.small-screen--hide
  display: block !important
  +media-query(650px)
    display: none !important
    

.slide-overlay
  cursor: pointer
  display: none
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 120%
  background: rgba(0, 0, 0, 0.5)
  z-index: 10


.table-wrapper
  width: 0
  height: 0
  overflow: hidden


+media-query(1100px)
  #site
    margin: 0 auto
    right: 0
    box-shadow: 0px 0px 10px -3px #000
    +transition(right 200ms ease 100ms)

    &__content
      max-width: 800px
      .site__wrapper
        padding: 20px
      .site__title
        h2, p, span, a
          font-size: 32px
          font-weight: 400
      .site__base
        width: 51%
      .site__aside
        width: 43%
      .site__middle-screen
        display: block
        
+media-query(650px)
  #site
    &__content
      max-width: 400px
      .site__title
        text-align: center
        h2, p, span, a
          font-size: 26px
          font-weight: 700
      .site__base
        width: 100%
        margin: 0
      .site__aside
        width: 100%
        margin: 0
      .site__small-screen
        display: block
  form
    .fieldset
      &--small
        width: 100%
        padding: 0 !important


@import "_header"
@import "_footer"

@import "_navigation/_categories-menu"
@import "_navigation/_sub-menu"