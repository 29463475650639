.sub-menu
  display: block
  position: relative
  margin-top: 6px
  background: $colorBlue
  .nav__items
    display: block
    position: relative
    margin: -5px -10px
    padding: 10px 0
    overflow: hidden
    li
      display: inline-block
      vertical-align: middle
      margin: 0
      padding: 0
      &::before
        display: none
      &::after
        content: ""
        position: absolute
        width: 1px
        height: 70%
        top: 15%
        left: -1px
        margin: 0
        padding: 0
        background: lighten($colorBlueSecondary, 15%)
  .nav__item
    display: block
    margin: 5px 0
    padding: 5px 10px
    font-size: 12px
    font-weight: 100
    color: #fff
    &:hover
      text-decoration: underline
    &.active
      font-weight: 400
      color: lighten($colorBlue, 35%)
  .sub-category
    a, p, span
      display: block
      margin: 5px 0
      padding: 5px 10px
      font-size: 12px
      color: #fff
    .fa
      margin-right: 4px
      vertical-align: middle
      font-size: 20px
      color: #fff !important
    .active
      color: lighten($colorBlue, 35%) !important
      
+media-query(1100px)
  .sub-menu
    .nav__items
      li       
        border-bottom: 1px lighten($colorBlueSecondary, 10%) solid
        &::after
          display: none
        .fa
          display: none
    // .sub-category
    //   display: block
    //   margin-bottom: 15px
          

        
  