.portlets
	display: block
	margin: 0 -2%
	font-size: 0
	+clearfix
	.portlet
		width: 100%
		margin-bottom: 30px
	&__col
		width: 46%
		margin: 0 2%
		float: left
		&--large
			width: 96%
.portlet
	display: inline-block
	vertical-align: top
	border: 3px #eee solid
	color: #111
	header
		display: table
		width: 100%
		padding: 5px 10px
		color: #7a7a7a
		background: #eee
	&__name, &__options
		display: table-cell
		vertical-align: middle
	&__name
		p, a, ul, ol
			font-family: 'futura-pt'
			font-size: 14px
			font-weight: 600
			text-transform: uppercase
			letter-spacing: 1px
			color: inherit
			i
				margin-right: 5px
	&__options
		font-size: 0
		text-align: right
		a
			display: inline-block
			vertical-align: middle
			padding: 4px 7px 3px 7px
			font-size: 8px
			text-transform: uppercase
			color: $colorBlue
			background: #fff
			border-radius: 3px
			+transition(all 150ms linear)
			i
				margin-right: 4px
			&:hover
				color: #fff
				background: $colorBlueSecondary
	&__content
		font-size: 14px
		+clearfix
		p, a, ul, ol
			font-size: 12px
			color: inherit
			line-height: 1
		a
			color: $colorBlue


.portlet
	&--awarded
		color: $colorBlueSecondary
		border-color: $colorBlueSecondary
		header
			color: #fff
			background: $colorBlueSecondary
	&--large
		width: 100%


.p-col__title
	display: block
	text-align: right
	h2
		margin: 0
		margin-bottom: 15px
		font-size: 18px
		font-weight: 300
		text-align: left
		color: $colorBlueSecondary
		line-height: 1.2


.p-panel
	display: block
	width: 100%
	margin-bottom: 20px
	padding: 15px
	background: #f2f2f2
	&__title
		display: block
		margin: 10px 0 20px 0
		font-size: 11px
		font-weight: 400
		text-transform: uppercase
		text-align: center
		color: $colorBlue
	&__text
		padding-bottom: 10px
		text-align: center
		color: lighten($colorBlueSecondary, 10%)
		*
			color: inherit
		p
			font-size: 14px
			font-weight: 300
		h4
			display: block
			margin: 0
			font-size: 18px
			font-weight: 300
			text-align: center
			line-height: 1.4
	&__types
		display: block
		margin: -1%
		+clearfix
		.types
			&__type
				position: relative
				display: table
				width: 48%
				margin: 1%
				padding: 15px
				float: left
				background: #fff
				border: 2px transparent solid
				border-radius: 3px
				&.choice
					cursor: pointer
					&:hover
						border-color: lighten($colorBlue, 40%)
				&.active
					border-color: $colorBlue !important
					&::before
						content: ""
						display: block
						position: absolute
						top: -20px
						right: -20px
						width: 40px
						height: 40px
						border-width: 20px
						border-color: transparent
						border-style: solid
						border-left: 20px $colorBlue solid
						+transform(rotate(-45deg))
					&::after
						content: "\f00c"
						font-family: FontAwesome
						position: absolute
						top: 2px
						right: 2px
						font-size: 12px
						color: #fff
						line-height: 1
				&--text
					padding: 0 15px
					background: transparent
					.p-panel__title
						margin-top: 0px

				.p-panel__title
					text-align: left
				.p-panel__text *
					text-align: left
					

				.type
					&__text, &__price
						display: table-cell
						vertical-align: middle
					&__text
						p
							font-size: 18px
							font-weight: 200
							color: $colorBlue
							strong
								color: $colorBlueSecondary
					&__price
						padding: 0 25px
						p
							font-family: 'futura-pt'
							font-size: 36px
							font-weight: 600
							color: $colorBlue
					

+media-query(1100px)
	.portlets
		margin: 15px 0
		&__col
			width: 100%
			margin: 0
		.portlet
			[class*="col-"]
				width: 100%

+media-query(650px)
	.p-panel
		&__types
			.types
				&__type
					display: block
					width: 98%
					text-align: center
					.type
						&__text, &__price
							display: block
							width: 100%