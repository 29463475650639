.text
	display: block
	position: relative
	margin-bottom: 0 !important
	p, a, ul, ol, h1, h2, h3, h4, h5
		margin: 0
		padding: 0
		text-transform: none
	h1, h2, h3
		line-height: 1.4
	h1
		font-size: 32px
	h2
		font-size: 26px
	h3
		font-size: 22px
	h4
		font-size: 22px
		font-weight: 400
	h5
		font-size: 16px

	p, a, ol ,ul, li
		font-size: 14px
		font-weight: 300
		line-height: 1.4

	ul li::before, ol li::before
		color: $colorBlueSecondary

	table
		thead
			background: $colorBlueSecondary
			th, td
				padding: 15px
				color: #fff
				line-height: 1.1
				border: 1px darken($colorBlueSecondary, 10%) solid

