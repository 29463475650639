.header
  display: block
  position: relative
  &__wrapper
    +wrapper($siteWidth)
    padding: 0 30px

  &__top-bar
    display: block
    width: 100%
    padding: 15px 0
    background: #fff
    +clearfix
    .top-bar__blocks
      display: table
      *display: block
      width: 100%
      +clearfix
    .top-bar__block
      display: table-cell
      *display: block
      *float: left
      width: 50%
      margin: 0
      vertical-align: middle
      font-size: 12px
      &:nth-child(even)
        text-align: right
      
  // ================= //
  // ==== TOP BAR ==== //
  &__logo,
  &__fb-like,
  &__icons,
  &__buttons
    display: inline-block
    vertical-align: middle
    margin: 0 10px

  &__logo
    margin-right: 30px
    width: 40%
    min-width: 150px
    a
      display: block
    img, object
      width: 100%
    object
      display: inline-block
      max-width: 100%
      max-height: 60px
      pointer-events: none

      

  &__icons
    position: relative
    margin: -5px
    .icons 
      &__ico
        display: inline-block
        margin: 0 5px
        padding: 3px
        font-size: 16px
        color: $colorBlueSecondary
        &:hover
          color: $colorBlue

  &__buttons
    margin-left: 20px
    border: 1px #ddd solid
    border-radius: 5px
    overflow: hidden
    +clearfix
    .buttons
      &__button
        display: inline-block
        vertical-align: middle
        padding: 10px
        font-size: 10px
        font-weight: 700
        text-transform: uppercase
        color: $colorBlueSecondary
        line-height: 1
        &:not(:last-child)
          border-right: 1px #ddd solid
        &:hover
          color: $colorBlue
        i.fa
          margin-right: 5px
          font-size: 1.4em
          line-height: 0.6
          vertical-align: middle
          display: inline-block
  &__info-button
    display: none
    text-align: right
    .button-radius  
      display: inline-block
      vertical-align: middle
      font-size: 18px
      width: 40px
      height: 40px
      border: 3px $colorBlue solid
      text-align: center
      border-radius: 50%
      color: $colorBlue
      cursor: pointer
      overflow: hidden
      &.hovered
        border: 0px
        width: 40px
        height: 40px
        color: #fff
        .fa
          background: #0b2e62
          color: #fff
          padding: 12px 0

      .fa
        display: block
        padding: 9px 0
        background: transparent
        line-height: 1em

      // .header__info-button .button-radius.hovered, .header__info-button .button-radius.active {
      //   border-color: #0b2e62;
      // }
      //   .header__info-button .button-radius.hovered .fa, .header__info-button .button-radius.active .fa{
      //     background: #0b2e62;
      //     color: #fff; }

  &__hamburger
    display: none
    $bar_color: #fff
    $size: 25px
    $bar_height: 3px
    $offset: $bar_height * 1.95
    .menu_toggle 
      position: relative
      display: block
      margin: auto
      height: ($offset*2) + $bar_height
      width: $size
      cursor: pointer
      .bars 
        position: absolute
        top: 50%
        left: 0
        margin-top: -($bar_height/2)
        display: block
        width: $size
        height: $bar_height
        background: $bar_color
        border-radius: 2px
        &:before, &:after 
          content: ""
          position: absolute
          display: block
          width: $size
          height: $bar_height
          background-color: $bar_color
          border-radius: 2px
          +transition(.2s linear)
        &:before
          top: -$offset 
        &:after
          bottom: -$offset 
    .active 
      .bars 
        background-color: transparent
        +transition (all .2s linear)
        &:before 
          top: 0
          +transform(rotate(45deg))       
        &:after 
          bottom: 0
          +transform(rotate(-45deg))

  &__base-menu
    display: block
    position: relative
    margin-bottom: 5px
    padding-bottom: 5px
    background: $colorBlueSecondary
    &::after
      content: ""
      display: block
      width: 100%
      height: 4px
      position: absolute
      left: 0
      bottom: -5px
      background: $colorBlue
  .base-menu
    &__button
      display: none
    .menu__button
      display: none
    .header__navs
      position: static
      &--mobile
        display: none
        

  .parent-name
    display: none
    padding: 20px 0 0 0
    text-align: center
    a, p, span
      font-size: 22px
      font-weight: 600
      color: #fff

.header__slide-menu
  display: block
  height: 120%
  top: 0
  bottom: -20%
  right: -480px
  left: auto
  width: 400px
  padding: 0
  position: fixed
  background: #fff
  z-index: 999
  +transition(right 200ms ease 100ms)
  +clearfix
  &.slide-menu--hidden
    right: -480px
  &.slide-menu--visible
    right: 0px
    box-shadow: -10px -10px 15px -10px #000
.slide-menu
  .header__wrapper
    position: relative
    right: -20px
    display: block
    padding-left: 10px
    height: 100%
    font-size: 0
    overflow-y: scroll
  &__buttons, &__icons
    position: relative
    display: block
    font-size: 14px
    text-align: center
    .search-box
      top: 95%
      left: 0
      right: 0
      &::before
        display: none
  .close-slide-menu
    display: inline-block
    position: absolute
    top: 0px
    left: -60px
    width: 60px
    height: 55px
    font-size: 46px
    color: $colorBlueSecondary
    background: #fff
    cursor: pointer
    &:hover
      color: $colorBlue
      svg path
        fill: $colorBlue
    img, object, svg
      width: 100%
      height: 100%
      cursor: pointer

    
  &__buttons
    margin: 0 auto
    margin-bottom: 5px
    border: 1px #ddd solid
    border-radius: 5px
    text-align: center
    +clearfix
    .buttons__button
      display: inline-block
      vertical-align: middle
      padding: 10px
      font-size: 10px
      font-weight: 700
      text-transform: uppercase
      color: $colorBlueSecondary
      line-height: 1
      &:not(:last-child)
        border-right: 1px #ddd solid
      &:hover
        color: $colorBlue
      i.fa
        margin-right: 5px
        font-size: 1.4em
        line-height: 0.6
        vertical-align: middle
        display: inline-block
      
  &__icons
    margin-bottom: 5px
    .icons__ico
      display: inline-block
      vertical-align: middle
      margin: 5px 5px
      padding: 5px
      font-size: 36px
      color: $colorBlueSecondary
      &:hover
        color: $colorBlue


  &__links
    display: block
    margin: 20px 0
    padding: 10px 0
    border-top: 3px #eee solid
    ul
      display: block
      margin: 0
      text-align: left
      &.links--important
        margin-bottom: 10px
        padding-bottom: 10px
        border-bottom: 3px #eee solid
        li a
          font-weight: 700
    li
      margin: 0
      padding: 0
      &::before
        display: none
      a
        display: block
        padding: 6px 0 4px
        font-size: 16px
        font-weight: 400
        color: $colorBlueSecondary
        &:hover
          color: #aaa
      
    

    



@media(min-width: 1100px)
  .header
    &__base-menu
      .header__navs
        width: 100% !important
  .header__slide-menu
    &.slide-menu--hidden
      right: -480px
    &.slide-menu--visible
      right: -480px
  .slide-overlay
    display: none !important
    


+media-query(1100px)
  // ################
  // BASE WIDTH 770px
  // ################

  .header__bottom-menu
    display: block
    
  .header
    &__logo,
    &__fb-like,
      margin: 0
    &__logo
      img
        max-width: 200px
    &__fb-like, &__tooltip
      display: none
    &__hamburger
      display: inline-block
      vertical-align: middle
    &__info-button
      display: block
    &__tooltip
      position: absolute
      top: 100%
      right: 20px
      background: #fff
      padding: 10px
      box-shadow: 0px 0px 10px -2px #000
      text-align: center
      z-index: 100
      opacity: 0
      &::after
        content: ""
        width: 20px
        height: 10px
        border: 10px #fff solid
        border-top: 0px
        border-color: transparent transparent #fff transparent
        position: absolute
        top: -10px
        right: 10px
        z-index: 2
      &::before
        content: ""
        width: 21px
        height: 11px
        border: 11px #ddd solid
        border-top: 0px
        border-color: transparent transparent #ddd transparent
        position: absolute
        top: -13px
        right: 9px
        z-index: 1
      &.active
        display: block
        +fadeIn(300ms)

      
      .header__icons
        margin-bottom: 5px
        .icons__ico
          font-size: 24px
      .header__buttons
        display: table
        .buttons__button
          display: table-cell
    &__base-menu
      margin: 0
      padding: 0
      margin-top: 5px
      &::after
        top: -5px
        bottom: auto
      .header__wrapper
        padding: 0
        position: static
      .base-menu__button
        display: block
        text-align: center
        box-shadow: 0px 0px 10px -2px #000
        a
          display: inline-block
          width: 100%
          padding: 25px
          font-size: 16px
          font-weight: 600
          color: #fff
          .fa
            display: inline-block
            vertical-align: middle
            margin-left: 5px
            font-size: 11px
            line-height: 20px
            +transition(all 150ms linear)
          &.show .fa
            +transform(rotate(-180deg))
        p
          display: inline-block
          vertical-align: middle
          padding: 2px 5px 0px 5px
          font-size: 19px
          font-weight: 700
          text-transform: uppercase

    .base-menu  
      .header__navs
        display: none
        &--mobile
          display: none
          padding: 20px
          .menu-group, li
            margin: 0
            padding: 0
          .menu-group.active
            display: block
            
          li
            position: relative
            margin-bottom: 2px
            background: lighten($colorBlueSecondary, 5%)
            border-top: 1px $colorBlueSecondary solid
            &::before
              display: none
            &.current
              background: lighten($colorBlueSecondary, 8%)
              > a
                text-decoration: underline
            &.active
              background: lighten($colorBlueSecondary, 8%)
              
                
            .indicator
              position: absolute
              margin: auto
              width: 50px
              height: 100%
              top: 0
              bottom: 0
              right: 0
              font-size: 17px
              line-height: 1
              border-left: 2px $colorBlueSecondary solid
              text-align: center
              color: #fff
              background: darken($colorBlue, 30%)
              z-index: 9
              .fa
                position: absolute
                margin: auto
                height: 17px
                left: 0
                right: 0
                top: 0
                bottom: 0
                line-height: 19px
          .nav__item
            display: block
            padding: 10px 60px 10px 10px
            font-weight: 300
            color: #fff
            &:hover
              text-decoration: underline
          .submenu
            display: none
            // &.active
            //   display: block
            .nav__item
              padding-left: 20px
          
          .mobile-menu
            > .menu-group > li
              margin-bottom: 5px
            .submenu a
              padding-left: 40px
              font-size: 13px
            .submenu .submenu a
              padding-left: 60px
              font-size: 12px

   
     
















































+media-query(650px)
  .header
    .header__wrapper
      padding: 0 20px
    
  
    &__logo img
      max-width: 150px
    &__tooltip
      top: 120%
      .header__icons
        margin-bottom: 5px
        .icons__ico
          margin: 0 10px
          font-size: 32px
      .header__buttons
        display: block
        margin: 0
        padding: 10px
        .buttons__button
          display: block
          width: 100%
          font-size: 18px
          font-weight: 700
          border: 0
  .header__slide-menu
    display: block
    top: 0
    bottom: 0
    right: -100%
    left: auto
    width: 80%
    &.slide-menu--hidden
      right: -100%



+media-query(500px)
  .slide-menu
    text-align: center
    &__buttons
      margin: 0 auto
      margin-bottom: 5px
      border: 0px
      +clearfix
      .buttons__button
        display: block
        vertical-align: middle
        margin-bottom: 5px
        padding: 5px 0
        font-size: 16px
        font-weight: 700
        text-transform: none
        color: $colorBlueSecondary
        line-height: 1
        border: 1px #ccc solid !important
        border-radius: 5px
        &:hover
          color: $colorBlue
        i.fa
          margin-right: 5px
          font-size: 1.4em
          line-height: 0.6
          vertical-align: middle
          display: inline-block