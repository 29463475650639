.user-login
	display: block
	width: 100%
	max-width: 505px
	margin: 0 auto
	h1
		margin: 0
		font-weight: 300
		text-align: left
		color: $colorBlueThird
	form
		margin-bottom: 20px
		padding-bottom: 10px
		border-bottom: 2px #eee solid

.user-login-info
	display: block
	text-align: center
	font-size: 14px
	color: $colorBlueThird
	a
		text-decoration: underline