.register-summary
	position: relative
	display: block
	width: 100%
	max-width: 600px
	margin: 30px auto 60px auto
	padding: 0 20px
	&--large
		max-width: 840px
		.register-summary__content
			max-width: none
			> h4
				color: $colorBlueThird
	&__name
		display: block
		text-align: center
		h1
			font-weight: 300
			color: $colorBlueThird
	&__content
		max-width: 400px
		margin: 0 auto
		> h4
			display: block
			margin-bottom: 0px
			font-size: 18px
			font-weight: 300
			text-align: center
			color: $colorBlueThird
			line-height: 1.4
			span
				display: inline-block
				margin: 10px 0
				padding: 7px 15px 5px 15px
				background: #f2f2f2
				border-radius: 3px
		.p-panel
			display: block
			width: 100%
			margin-top: 10px
			.types
				&__type
					width: 98%
	.buttons
		text-align: center
		.button
			margin-bottom: 10px



+media-query(650px)
	.register-summary
		padding: 0px