.photo
	display: block
	margin: 10px 20px
	margin-bottom: 10px !important
	padding-bottom: 0
	position: relative
	border-bottom: 2px #eee solid
	z-index: 99
	&--left
		float: left
		margin-left: 0px
		max-width: 50%
		img
			width: 100%
	&--right
		float: right
		margin-right: 0px
		max-width: 50%
		img
			width: 100%
	&--center
		margin: 20px 0
		margin-bottom: 40px !important
		text-align: center
		clear: both
		img
			display: inline-block
			box-shadow: 0px -150px 0px 150px #eee
	&--wide
		margin: 20px 0
		margin-bottom: 40px !important
		clear: both
		figure
			width: 100%
			img
				width: 100%
		
	figure
		display: block
		position: relative
		overflow: hidden
		margin: auto
		img
			max-width: 100%
		figcaption
			display: block
			caption-side: bottom
			padding: 7px 0 5px 0
			left: 0
			right: 0
			bottom: 0
			background: rgba(255, 255, 255, 0.9)
			text-align: left
			+transition(all 150ms linear)
			p 
				font-size: 12px
				font-weight: 400
				color: #000
			span
				display: block
				padding: 3px 0
				font-style: italic
				color: #999

		.litebox
			display: block
			background: #000
			text-align: center
			img
				+transition(opacity 150ms linear)

		&:hover
			.litebox::after
        content: "\f065"
        font-family: FontAwesome
        font-size: 30px
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        padding: 6px
        width: 50px
        height: 50px
        color: #fff
        z-index: 999
        line-height: 1
			.litebox
				img
					opacity: 0.4
				+ figcaption
					top: 100%
					