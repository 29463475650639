@-webkit-keyframes bounceOut {
  0% {-webkit-transform: scale(1);}
  25% {-webkit-transform: scale(.95);}
  50% {opacity: 1; -webkit-transform: scale(1.1);}
  100% {opacity: 0; -webkit-transform: scale(.3);}
}

@-moz-keyframes bounceOut {
  0% {-moz-transform: scale(1);}
  25% {-moz-transform: scale(.95);}
  50% {opacity: 1; -moz-transform: scale(1.1);}
  100% {opacity: 0; -moz-transform: scale(.3);}
}

@-ms-keyframes bounceOut {
  0% {-ms-transform: scale(1);}
  25% {-ms-transform: scale(.95);}
  50% {opacity: 1; -ms-transform: scale(1.1);}
  100% {opacity: 0; -ms-transform: scale(.3);}
}

@-o-keyframes bounceOut {
  0% {-o-transform: scale(1);}
  25% {-o-transform: scale(.95);}
  50% {opacity: 1; -o-transform: scale(1.1);}
  100% {opacity: 0; -o-transform: scale(.3);}
}

@keyframes bounceOut {
  0% {transform: scale(1);}
  25% {transform: scale(.95);}
  50% {opacity: 1; transform: scale(1.1);}
  100% {opacity: 0; transform: scale(.3);}
}

@mixin bounceOut($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(bounceOut);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
