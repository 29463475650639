@-webkit-keyframes flip360 {
    0% {-webkit-transform: perspective(400px) rotateX(0deg); opacity: 1; background-color: #fff; color: #1d1d1d;}
  100% {-webkit-transform: perspective(400px) rotateX(360deg); opacity: 1; background-color: #555; color: #fff;}
}

@-moz-keyframes flip360 {
    0% {-moz-transform: perspective(400px) rotateX(0deg); opacity: 1; background-color: #fff; color: #1d1d1d;}
  100% {-moz-transform: perspective(400px) rotateX(360deg); opacity: 1; background-color: #555; color: #fff;}
}

@-ms-keyframes flip360 {
    0% {-ms-transform: perspective(400px) rotateX(0deg); opacity: 1; background-color: #fff; color: #1d1d1d;}
  100% {-ms-transform: perspective(400px) rotateX(360deg); opacity: 1; background-color: #555; color: #fff;}
}

@-o-keyframes flip360 {
    0% {-o-transform: perspective(400px) rotateX(0deg); opacity: 1; background-color: #fff; color: #1d1d1d;}
  100% {-o-transform: perspective(400px) rotateX(360deg); opacity: 1; background-color: #555; color: #fff;}
}

@keyframes flip360 {
    0% {transform: perspective(400px) rotateX(0deg); opacity: 1; background-color: #fff; color: #1d1d1d;}
  100% {transform: perspective(400px) rotateX(360deg); opacity: 1; background-color: #555; color: #fff;}
}

@mixin flip360($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(flip360);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
