@-webkit-keyframes fadeOutRight {
  0% {opacity: 1; -webkit-transform: translateX(0);}
  100% {opacity: 0; -webkit-transform: translateX(20px);}
}

@-moz-keyframes fadeOutRight {
  0% {opacity: 1; -moz-transform: translateX(0);}
  100% {opacity: 0; -moz-transform: translateX(20px);}
}

@-ms-keyframes fadeOutRight {
  0% {opacity: 1; -ms-transform: translateX(0);}
  100% {opacity: 0; -ms-transform: translateX(20px);}
}

@-o-keyframes fadeOutRight {
  0% {opacity: 1; -o-transform: translateX(0);}
  100% {opacity: 0; -o-transform: translateX(20px);}
}

@keyframes fadeOutRight {
  0% {opacity: 1; transform: translateX(0);}
  100% {opacity: 0; transform: translateX(20px);}
}

@mixin fadeOutRight($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeOutRight);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
