/**
*
* Articles section
*
**/

.articles-section {
  background: $colorGrayBackground;
  text-align: center;
  padding: $lineHeight 0 $lineHeight*4;
}

.articles-count {
  color: $colorBlue;
  font-family: $fontHighlight;
  font-weight: 400;
}

.article-section__icon {
  top: -($lineHeight);

  @include medium {
    top: -($lineHeight + $lineHeight/2);
  }
}
