@-webkit-keyframes flipInY {
  0% {-webkit-transform: perspective(400px) rotateY(90deg); opacity: 0;}
  40% {-webkit-transform: perspective(400px) rotateY(-10deg);}
  70% {-webkit-transform: perspective(400px) rotateY(10deg);}
  100% {-webkit-transform: perspective(400px) rotateY(0deg); opacity: 1;}
}
@-moz-keyframes flipInY {
  0% {-moz-transform: perspective(400px) rotateY(90deg); opacity: 0;}
  40% {-moz-transform: perspective(400px) rotateY(-10deg);}
  70% {-moz-transform: perspective(400px) rotateY(10deg);}
  100% {-moz-transform: perspective(400px) rotateY(0deg); opacity: 1;}
}
@-ms-keyframes flipInY {
  0% {-ms-transform: perspective(400px) rotateY(90deg); opacity: 0;}
  40% {-ms-transform: perspective(400px) rotateY(-10deg);}
  70% {-ms-transform: perspective(400px) rotateY(10deg);}
  100% {-ms-transform: perspective(400px) rotateY(0deg); opacity: 1;}
}
@-o-keyframes flipInY {
  0% {-o-transform: perspective(400px) rotateY(90deg); opacity: 0;}
  40% {-o-transform: perspective(400px) rotateY(-10deg);}
  70% {-o-transform: perspective(400px) rotateY(10deg);}
  100% {-o-transform: perspective(400px) rotateY(0deg); opacity: 1;}
}
@keyframes flipInY {
  0% {transform: perspective(400px) rotateY(90deg); opacity: 0;}
  40% {transform: perspective(400px) rotateY(-10deg);}
  70% {transform: perspective(400px) rotateY(10deg);}
  100% {transform: perspective(400px) rotateY(0deg); opacity: 1;}
}

@mixin flipInY($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(flipInY);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
