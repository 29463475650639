.ui-datepicker
	box-shadow: 0px 0px 20px -5px #000
.ui-datepicker-header
	position: relative
	padding: 10px 50px
	text-align: center
	color: #fff
	background: $colorBlueSecondary
.ui-datepicker-prev, .ui-datepicker-next
	position: absolute
	top: 8px
	span
		font-size: 0
		cursor: pointer
		&::before
			font-family: FontAwesome
			font-size: 26px
			color: #fff
	&:hover
		span
			opacity: 0.8
.ui-datepicker-prev
	left: 10px
	span::before
		content: "\f137"
.ui-datepicker-next
	right: 10px
	span::before
		content: "\f138"
		


.ui-datepicker-calendar
	margin: 0 !important
	background: #fff
	thead
		background: $colorBlue
		color: #fff
		th
			padding: 10px !important
			color: #fff
			border: 0px
	tbody
		td
			padding: 0
			min-height: 0px
			text-align: center
			a
				display: block
				padding: 10px
				&:hover
					background: #eee