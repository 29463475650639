@-webkit-keyframes fadeOutDownBig {
  0% {opacity: 1; -webkit-transform: translateY(0);}
  100% {opacity: 0; -webkit-transform: translateY(2000px);}
}

@-moz-keyframes fadeOutDownBig {
  0% {opacity: 1; -moz-transform: translateY(0);}
  100% {opacity: 0; -moz-transform: translateY(2000px);}
}

@-ms-keyframes fadeOutDownBig {
  0% {opacity: 1; -ms-transform: translateY(0);}
  100% {opacity: 0; -ms-transform: translateY(2000px);}
}

@-o-keyframes fadeOutDownBig {
  0% {opacity: 1; -o-transform: translateY(0);}
  100% {opacity: 0; -o-transform: translateY(2000px);}
}

@keyframes fadeOutDownBig {
  0% {opacity: 1; transform: translateY(0);}
  100% {opacity: 0; transform: translateY(2000px);}
}

@mixin fadeOutDownBig($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeOutDownBig);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
