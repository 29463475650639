@-webkit-keyframes fadeOutUp {
  0% {opacity: 1; -webkit-transform: translateY(0);}
  100% {opacity: 0; -webkit-transform: translateY(-20px);}
}

@-moz-keyframes fadeOutUp {
  0% {opacity: 1; -moz-transform: translateY(0);}
  100% {opacity: 0; -moz-transform: translateY(-20px);}
}

@-ms-keyframes fadeOutUp {
  0% {opacity: 1; -ms-transform: translateY(0);}
  100% {opacity: 0; -ms-transform: translateY(-20px);}
}

@-o-keyframes fadeOutUp {
  0% {opacity: 1; -o-transform: translateY(0);}
  100% {opacity: 0; -o-transform: translateY(-20px);}
}

@keyframes fadeOutUp {
  0% {opacity: 1; transform: translateY(0);}
  100% {opacity: 0; transform: translateY(-20px);}
}

@mixin fadeOutUp($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeOutUp);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
