.search-box
	display: none
	padding: 10px 15px
	padding-right: 40px
	position: absolute
	right: 0
	top: 115%
	border: 1px #ddd solid
	border-radius: 5px
	z-index: 10
	background: #fff
	white-space: nowrap
	&::before
		content: ""
		width: 10px
		height: 10px
		background: #ddd
		border-bottom: 5px #ddd solid
		border-left: 5px #fff solid
		border-right: 5px #fff solid
		position: absolute
		top: -10px
		right: 10px
	input
		display: inline-block
		width: auto
		padding: 5px 10px
		font-size: 12px
		border: 1px #eee solid
		&[type="submit"]
			width: auto
			border: 0px
			font-size: 12px
			font-weight: 600
			color: #fff
			background: $colorBlueSecondary
			&:hover
				background: $colorBlue
	&__close
		position: absolute
		right: 10px
		top: 2px
		font-size: 16px
		color: $colorBlueSecondary
		cursor: pointer
		&:hover
			color: $colorBlue