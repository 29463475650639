/**
*
* Themed styles
*
**/

.themed {

  .theme--multi-device-layouts & { color: $colorLayouts; }

  .theme--introduction-to-media & { color: $colorMedia; }

  .theme--user-input & { color: $colorUser; }

  .theme--performance & { color: $colorPerformance; }

}

.themed--background {

  .theme--multi-device-layouts &,
  .theme--multi-device-layouts &.next-lessons::before { background-color: $colorLayouts; }

  .theme--introduction-to-media &,
  .theme--introduction-to-media &.next-lessons::before { background-color: $colorMedia; }

  .theme--user-input &,
  .theme--user-input &.next-lessons::before { background-color: $colorUser; }

  .theme--performance &,
  .theme--performance &.next-lessons::before { background-color: $colorPerformance; }

}

.themed--hover {

  .theme--multi-device-layouts &:hover { color: $colorLayouts; }

  .theme--introduction-to-media &:hover { color: $colorMedia; }

  .theme--user-input &:hover { color: $colorUser; }

  .theme--performance &:hover { color: $colorPerformance; }

}

.themed--hover-secondary {

  .theme--multi-device-layouts &:hover { color: $colorLayoutsSecondary; }

  .theme--introduction-to-media &:hover { color: $colorMediaSecondary; }

  .theme--user-input &:hover { color: $colorUserSecondary; }

  .theme--performance &:hover { color: $colorPerformanceSecondary; }

}


// Wrapped content in .article-container so we can
// encapsulate what needs to be themed without overriding
// anything outside of this container.
.article-container h1,
.article-container h2 {

  .article--multi-device-layouts & { color: $colorLayouts; }

  .article--introduction-to-media & { color: $colorMedia; }

  .article--user-input & { color: $colorUser; }

  .article--performance & { color: $colorPerformance; }
}


// Let's make sure this is at the end so we can override
// previous rules. Specicifity rules.
.themed--hover {

  .nav-theme--multi-device-layouts &:hover { color: $colorLayouts; }

  .nav-theme--introduction-to-media &:hover { color: $colorMedia; }

  .nav-theme--user-input &:hover { color: $colorUser; }

  .nav-theme--performance &:hover { color: $colorPerformance; }

}

.themed {

  .nav-theme--multi-device-layouts & { color: $colorLayouts; }

  .nav-theme--introduction-to-media & { color: $colorMedia; }

  .nav-theme--user-input & { color: $colorUser; }

  .nav-theme--performance & { color: $colorPerformance; }

}

.themed--background {

  .nav-theme--multi-device-layouts & { background-color: $colorLayouts; }

  .nav-theme--introduction-to-media & { background-color: $colorMedia; }

  .nav-theme--user-input & { background-color: $colorUser; }

  .nav-theme--performance & { background-color: $colorPerformance; }

}
