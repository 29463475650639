.events
  padding: 20px 0
  border-top: 3px #ddd solid
  .site__aside &
    padding: 0
    border: 0px
  &__title
    font-family: "futura-pt"
    font-size: 14px
    font-weight: 700
    text-transform: uppercase
    color: #777
  &__list
    display: block
    background: #fff
    +clearfix
    .site__aside &
      padding: 20px 10px
.event
  display: table
  table-layout: fixed
  width: 100%
  &:not(:last-child)
    margin-bottom: 15px
    padding-bottom: 15px
    border-bottom: 1px #ddd solid
  &__date
    display: table-cell
    
  &__content
    display: table-cell
    // width: 80%
    padding-left: 15px
    *
      font-size: 14px
      color: #000
      line-height: 1.4
    .name
      margin-bottom: 6px
      p
        font-size: 22px
        font-weight: 600
        color: $fontBaseDark
      .site__aside &
        p
          font-size: 14px
          font-weight: 400
    .description p
      color: #aaa
    .date p
      font-size: 13px
      color: #aaa
  &__more
    font-size: 10px
    font-weight: 400
    text-transform: uppercase
    color: $colorBlue
    .fa
      padding-left: 3px
      font-size: 9px
      color: $colorBlue
    &:hover
      text-decoration: underline


  &__month
    display: block
    width: 100%
    padding: 3px
    background: $colorBlue
    
  &__day
    display: block
    padding: 1px 0
    background: #fff
    border-bottom: 1px #ccc solid
    p
      display: block
      padding: 3px 0
      font-size: 25px
      font-weight: 400
      background: #ddd
      line-height: 1
      .site__aside &
        line-height: 1.6
  &__link:hover *
    color: $colorBlueSecondary


.event__date
  width: 55px
  .event__month, .event__day
    width: 50px
  .site__aside &
    width: 60px
    .event__month, .event__day
      width: 100%
  *
    display: block
    font-size: 12px
    font-weight: 100
    text-align: center
    color: #fff