@-webkit-keyframes rotateOutDownLeft {
	0% {-webkit-transform-origin: left bottom; -webkit-transform: rotate(0); opacity: 1;}
	100% {-webkit-transform-origin: left bottom; -webkit-transform: rotate(90deg); opacity: 0;}
}

@-moz-keyframes rotateOutDownLeft {
	0% {-moz-transform-origin: left bottom; -moz-transform: rotate(0); opacity: 1;}
	100% {-moz-transform-origin: left bottom; -moz-transform: rotate(90deg); opacity: 0;}
}

@-ms-keyframes rotateOutDownLeft {
	0% {-ms-transform-origin: left bottom; -ms-transform: rotate(0); opacity: 1;}
	100% {-ms-transform-origin: left bottom; -ms-transform: rotate(90deg); opacity: 0;}
}

@-o-keyframes rotateOutDownLeft {
	0% {-o-transform-origin: left bottom; -o-transform: rotate(0); opacity: 1;}
	100% {-o-transform-origin: left bottom; -o-transform: rotate(90deg); opacity: 0;}
}

@keyframes rotateOutDownLeft {
	0% {transform-origin: left bottom; transform: rotate(0); opacity: 1;}
	100% {transform-origin: left bottom; transform: rotate(90deg); opacity: 0;}
}

@mixin rotateOutDownLeft($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(rotateOutDownLeft);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
